import { ReactComponent as Folder } from '@sketch/icons/folder-closed-16'
import { ReactComponent as Document } from '@sketch/icons/document-16'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.foreground.secondary.D};

  & + & {
    margin-left: 12px;
  }
`

const iconCss = css`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`

export const FolderIcon = styled(Folder)`
  ${iconCss}
`

export const DocumentIcon = styled(Document)`
  ${iconCss}
`
