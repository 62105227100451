import styled, { css } from 'styled-components'
import { ButtonUnstyled, Pill as PillBase } from '@sketch/components'
import { ReactComponent as ChevronRightIcon } from '@sketch/icons/chevron-right-16'
import { breakpoint } from '@sketch/global-styles'
import { ReactComponent as Plus } from '@sketch/icons/plus-16'
import { InlineEditorForm as InlineEditorFormBase } from '../../components'

export const PlusIcon = styled(Plus)`
  width: 16px;
  height: 16px;
`

export const Pill = styled(PillBase)`
  margin-left: 8px;
`

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  flex-shrink: 0;

  pointer-events: none;
`

const chevronSize = css`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

export const ChevronButton = styled(ButtonUnstyled)`
  ${chevronSize}

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${({ theme }) => theme.radii.small};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }
`

export const ChevronEmpty = styled.div`
  ${chevronSize}
`

export const Chevron = styled(ChevronRightIcon)(
  ({ theme: { transitions } }) => css`
    width: 16px;
    height: 16px;
    opacity: 0.55;

    transition: transform ${transitions.duration[1]}
      ${transitions.timing.easeInOut};
  `
)

export const Icon = styled.div`
  width: 100%;
  height: 100%;

  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};

  opacity: 0.55;
`

export const ActionWrapper = styled.div`
  /* Fix positioning issue with dropdown in Safari */
  position: relative;

  opacity: 0;
  width: 0;

  margin-left: auto;
  margin-right: 0;
  display: flex;
  justify-content: center;

  transition: ${({ theme: { transitions } }) =>
    css`
      opacity ${transitions.duration[2]} ${transitions.timing.easeInOut},
      width ${transitions.duration[2]} ${transitions.timing.easeInOut};
    `};
`

export const ActionButton = styled(ButtonUnstyled)`
  display: inline-flex;
  color: currentColor;

  height: 32px;

  justify-content: center;
  align-items: center;

  border-radius: ${({ theme }) => theme.radii.large};

  :hover,
  :focus,
  :focus-within {
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  }
`

const hoverStyle = css`
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  /* Fix the hover color of the icon */
  ${Icon}, ${Chevron} {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    opacity: 1;
  }
`

const displayActionStyles = css`
  ${ActionWrapper}, ${ActionButton} {
    opacity: 1;
    width: 40px;
  }
`

const selectedStyle = css`
  background-color: ${({ theme }) => theme.colors.sketch.F};
  color: ${({ theme }) => theme.colors.sketch.A};

  ${Icon}, ${Chevron} {
    color: ${({ theme }) => theme.colors.sketch.A};
    opacity: 1;
  }

  ${ChevronButton} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.sketch.F};
    }
  }
`

export const TreeItem = styled.span<{ depth: number }>`
  overflow: hidden;

  width: 100%;
  height: 40px;

  ${breakpoint('sm')`
    height: 32px;
  `}

  display: flex;
  align-items: center;

  background-color: transparent;
  padding: 8px 0 8px ${({ depth }) => 16 * depth + 12}px;
  border-radius: 5px;

  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.D};
  line-height: 1.2;

  color: ${({ theme }) => theme.colors.foreground.secondary.B};

  cursor: pointer;

  transition: none;

  :hover,
  :focus,
  :focus-within {
    ${hoverStyle};
  }

  :hover {
    ${displayActionStyles};
  }

  // Display action button on touch devices
  @media (hover: none) {
    &[aria-selected='true'] {
      ${displayActionStyles};
    }
  }

  &[aria-selected='true'] {
    ${selectedStyle};
  }

  &[aria-expanded='true'] {
    ${Chevron} {
      transform: rotate(90deg);
    }
  }
`

export const TreeContainer = styled.div<{ creatingProject: boolean }>`
  ${TreeItem} {
    ${({ creatingProject }) =>
      creatingProject &&
      css`
        opacity: 0.3;
      `}
  }
`

export const TreeOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
`

export const InlineEditorFormContainer = styled.div`
  z-index: 2;
  position: relative;
`

export const InlineEditorForm = styled(InlineEditorFormBase)<{ depth: number }>`
  margin-left: ${({ depth }) => 16 * depth + 32}px;

  & input {
    outline-color: ${({ theme }) => theme.colors.sketch.D};
  }
`
