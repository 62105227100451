import { Avatar, Button, LinkButton } from '@sketch/components'
import {
  IndexLayoutContent as BaseIndexLayoutContent,
  IndexLayoutOldSubtitle,
} from '@sketch/modules-common'
import styled from 'styled-components'

export const IndexLayoutContent = styled(BaseIndexLayoutContent)`
  max-width: 380px;

  ${IndexLayoutOldSubtitle} {
    color: ${({ theme }) => theme.colors.foreground.secondary.C};
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 32px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background.secondary.A};
  border-radius: ${({ theme }) => theme.radii.large};

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 4px 8px 0 rgba(0, 0, 0, 0.08),
    0 0 4px 0 rgba(0, 0, 0, 0.08);
`

export const UserName = styled.span`
  color: ${({ theme }) => theme.colors.sketch.A};
`

export const UserAvatar = styled(Avatar)`
  margin-bottom: 14px; /* stylelint-disable-line scales/space */
`

export const ContinueButton = styled(LinkButton)`
  margin-top: 16px;
`

export const SwitchAccount = styled(Button)`
  && {
    margin-top: 16px;
    margin-left: 0;
  }
`

export const SignOutWrapper = styled.span`
  margin-top: 20px;
`
