import {
  ProjectFragment,
  ProjectInSidebarAndHeaderFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TreeNodeData } from '../state.base/CollapsibleTree'
import { CurrentIdsInput, TreeState } from '../state.base/TreeState'

export type TreeNodeTypes =
  | WorkspaceMinimalFragment
  // list of projects
  | ProjectInSidebarAndHeaderFragment
  // draft project
  | ProjectFragment

export type ProjectsTreeDataNode = TreeNodeData<TreeNodeTypes>

const treeStateContext = createContext<TreeState<TreeNodeTypes> | null>(null)

export interface TreeStateProviderProps extends PropsWithChildren<{}> {
  current: CurrentIdsInput
}

export const TreeStateProvider = (props: TreeStateProviderProps) => {
  const treeState = useMemo(
    () => {
      const currentId = props.current.projectId || props.current.workspaceId
      return new TreeState<TreeNodeTypes>(props.current, {
        focusedId: currentId,
        selectedId: currentId,
      })
    },

    // we are setting only the defaults, we DON'T need to re-create
    // the treeState once any of the props change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <treeStateContext.Provider value={treeState}>
      {props.children}
    </treeStateContext.Provider>
  )
}

export const useTreeState = () => {
  const treeState = useContext(treeStateContext)

  if (!treeState) {
    throw new Error('useTreeState must be used within a TreeStateProvider')
  }

  return treeState
}

export const useCurrentTreeState = () => {
  const treeState = useTreeState()

  const [selectedNode, setSelected] = useState(treeState.selected)

  useEffect(() => {
    const handleUpdate = () => {
      setSelected(treeState.selected)
    }

    return treeState.registerUpdatesListener(handleUpdate)
  }, [treeState])

  const currentNode = treeState.currentDestination
  const currentIds = treeState.currentIds

  return { selectedNode, currentNode, currentIds }
}
