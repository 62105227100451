import {
  Spinner,
  ErrorMessage,
  ModalInjectedProps,
  Modal,
} from '@sketch/components'
import { ShareInfoFragment, useGetShareQuery } from '@sketch/gql-types'
import React from 'react'
import { Footnote, FootnoteText } from './MoveDocumentModal.styles'

import {
  MoveDocumentModalFooter,
  MoveDocumentModalLayout,
} from './MoveDocumentModalLayout'
import {
  ProjectPickerContent,
  TreeStateProvider,
  TreeNodeTypes,
  useCurrentTreeState,
  useIsCreatingProject,
} from '../ProjectPickerContent'
import { useMoveDocument } from './useMoveDocument'
interface MoveModalFooterProps {
  onClick: (data: TreeNodeTypes) => void
  loading: boolean
}

export const MoveModalFooter = (props: MoveModalFooterProps) => {
  const { onClick, loading } = props
  // WARNING: Use useCurrentTreeState subscribes to all changes in the tree state
  // and will re-render the component on every change and can lead to severely
  // degraded performance issues on large trees if not used correctly.
  //
  // To use it correctly - use it in the lowest possible node of React components tree
  // to avoid unnecessary re-renders of other components.
  const { selectedNode, currentIds } = useCurrentTreeState()

  const { isCreatingProject } = useIsCreatingProject()

  const payload = selectedNode?.payload
  const isEnabled =
    payload?.identifier && payload?.identifier !== currentIds.currentId

  return (
    <MoveDocumentModalFooter
      disabled={!isEnabled}
      loading={loading}
      dimmed={isCreatingProject}
      onClick={() => {
        if (!payload) return
        onClick(payload)
      }}
    >
      <Footnote>
        <FootnoteText>
          {selectedNode?.payload &&
          selectedNode.payload.identifier !== currentIds.currentId ? (
            <>
              Move to <b>{selectedNode.payload.name}</b>
            </>
          ) : (
            <>
              Select a <b>project</b>
            </>
          )}
        </FootnoteText>
      </Footnote>
    </MoveDocumentModalFooter>
  )
}

interface MoveModalInnerProps {
  share: ShareInfoFragment
  hideModal: () => void
}

const MoveModalInner = (props: MoveModalInnerProps) => {
  const { share, hideModal } = props
  const [moveDocument, { loading, ConfirmationModal }] = useMoveDocument(
    share,
    hideModal
  )

  if (ConfirmationModal) {
    return <ConfirmationModal loading={loading} />
  }

  return (
    <MoveDocumentModalLayout share={share}>
      <ProjectPickerContent />
      <MoveModalFooter loading={loading} onClick={data => moveDocument(data)} />
    </MoveDocumentModalLayout>
  )
}

export interface MoveDocumentModalProps extends ModalInjectedProps {
  shareIdentifier: string
  workspaceIdentifier: string
  currentProjectIdentifier: string | null
}

export const MoveDocumentModal: React.FC<MoveDocumentModalProps> = props => {
  const { shareIdentifier, workspaceIdentifier, hideModal } = props
  const { loading, error, data } = useGetShareQuery({
    variables: { shortId: shareIdentifier },
  })

  if (loading) {
    return (
      <MoveDocumentModalLayout>
        <Modal.Body>
          <Spinner.Flex primary />
        </Modal.Body>
        <MoveDocumentModalFooter disabled loading />
      </MoveDocumentModalLayout>
    )
  }

  const share = data?.share
  if (error || !share) {
    return (
      <MoveDocumentModalLayout>
        <Modal.Body>
          <ErrorMessage.Generic />
        </Modal.Body>
        <MoveDocumentModalFooter disabled />
      </MoveDocumentModalLayout>
    )
  }

  const currentProjectId = share.project?.identifier || null

  return (
    <TreeStateProvider
      current={{
        projectId: currentProjectId,
        workspaceId: workspaceIdentifier,
      }}
    >
      <MoveModalInner share={share} hideModal={hideModal} />
    </TreeStateProvider>
  )
}
