import React from 'react'
import styled from 'styled-components'

import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  ArtboardDetailInfoFragment,
  useGetArtboardsForPageQuery,
} from '@sketch/gql-types'

import {
  Dropdown,
  ButtonUnstyled,
  InfiniteList,
  handleFetchMore,
  useResponsiveDropdown,
} from '@sketch/components'
import { useFlag } from '@sketch/modules-common'

import { useVersioning } from '../../../versioning'

import NavigationItem from './NavigationItem'

import { ChevronsIcon, DropdownItem } from './NavigationItem.styles'

const DROPDOWN_STYLE = {
  maxHeight: '80vh',
  overflow: 'auto',
} as const

interface FramesNavigationItemProps {
  shareIdentifier: string
  frame: ArtboardDetailInfoFragment
}

const FrameSelectorButton = styled(ButtonUnstyled)`
  min-width: 0px;
  max-width: 100%;
`

const FramesDropdown = ({
  frame,
  shareIdentifier,
}: FramesNavigationItemProps) => {
  const isFramesWebOn = useFlag('frames-web')
  const pageUUID = frame?.page?.uuid ?? null

  const { versionShortId, getPathname } = useVersioning()

  const { data, fetchMore, loading } = useGetArtboardsForPageQuery({
    variables: {
      identifier: shareIdentifier,
      versionShortId,
      pageUUID,
    },
  })

  // get frames for current page
  const frames = data?.share?.version?.document?.page?.artboards?.entries ?? []

  const after =
    data?.share?.version?.document?.page?.artboards?.meta?.after ?? null

  const onLoadMore = handleFetchMore(
    fetchMore,
    ['share', 'version', 'document', 'page', 'artboards', 'entries'],
    {
      dataIdFromObject,
      after,
    }
  )

  const getFrameLink = (frame: ArtboardDetailInfoFragment) =>
    getPathname({
      routeKey: 'ARTBOARD_DETAIL',
      routeParams: {
        shareID: shareIdentifier,
        permanentArtboardShortId: frame.permanentArtboardShortId!,
      },
    })

  if (loading) {
    return (
      <>
        <Dropdown.Header>
          {isFramesWebOn ? 'Frames' : 'Artboards'}
        </Dropdown.Header>
        <Dropdown.Skeleton />
      </>
    )
  }

  return (
    <InfiniteList onLoadMore={onLoadMore}>
      <Dropdown.Header>
        {isFramesWebOn ? 'Frames' : 'Artboards'}
      </Dropdown.Header>
      {frames.map(f => (
        <DropdownItem
          key={f.identifier}
          isActive={match => !!match || f.identifier === frame?.identifier}
          to={getFrameLink(f)}
        >
          {f.name}
        </DropdownItem>
      ))}
    </InfiniteList>
  )
}

export const FramesNavigationItem = (props: FramesNavigationItemProps) => {
  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: FramesDropdown,
    dropdownProps: props,
    dropdownStyle: DROPDOWN_STYLE,
  })

  const frameCount = props.frame?.page?.artboardCount || 0

  const isFramesWebOn = useFlag('frames-web')
  const navigationItemIcon = isFramesWebOn ? 'frame' : 'artboard'

  if (frameCount === 1) {
    return (
      <NavigationItem
        icon={navigationItemIcon}
        label={props.frame.name || ''}
      />
    )
  }

  return (
    <>
      <FrameSelectorButton data-testid="artboard-selector" {...buttonProps}>
        <NavigationItem
          icon={navigationItemIcon}
          label={props.frame.name || ''}
        />
        {frameCount > 1 && <ChevronsIcon />}
      </FrameSelectorButton>
      {content}
    </>
  )
}
