import { useApolloClient } from 'react-apollo'
import jwtDecode from 'jwt-decode'

import { CredentialsFragment } from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'

import {
  createPersonalAuthorization,
  getAllAuthorizations,
  replaceAllAuthorizations,
  setUserAuthorization,
} from './multisession'

const preventiveUserIdentifierDecode = (credentials: CredentialsFragment) => {
  try {
    return (jwtDecode(credentials.authToken) as any).sub
  } catch (e) {
    ErrorHandler.shouldNeverHappen(
      'AuthToken can not be decoded, incorrect format ?'
    )

    return null
  }
}

export const useSetUserAuthorization = () => {
  const client = useApolloClient()

  return async (credentials: CredentialsFragment) => {
    const newAuthorization = createPersonalAuthorization(credentials)

    /* Save the user credentials in the apollo cache */
    setUserAuthorization(client.cache, newAuthorization)

    const allAuths = getAllAuthorizations()

    if (allAuths.length > 1) {
      const decodedToken = preventiveUserIdentifierDecode(credentials)
      const nonUserIdMatched = allAuths.find(
        ({ fragment }) =>
          preventiveUserIdentifierDecode(fragment) !== decodedToken
      )

      if (nonUserIdMatched) {
        replaceAllAuthorizations(newAuthorization)
      }
    }
  }
}
