import {
  GetWorkspacesQuery,
  useGetWorkspacesQuery,
  WorkspaceMinimalFragment,
  WorkspaceMinimalWithBillingStatusFragment,
} from '@sketch/gql-types'
import { useMemo } from 'react'
import { isMemberWorkspace } from '../../../../workspace/utils'
import { isTruthy } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'

// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

type WorkspaceWrapped =
  | { type: 'PERSONAL'; data: WorkspaceMinimalFragment }
  | { type: 'REGULAR'; data: WorkspaceMinimalWithBillingStatusFragment }

const isEditableWorkspace = (workspace: WorkspaceMinimalFragment) => {
  return (
    isMemberWorkspace(workspace) &&
    (workspace.userCanEdit || workspace.userRole === 'ADMIN')
  )
}

// TODO: Replace this with a value received from the BE
//  see: https://github.com/sketch-hq/Cloud/issues/19657
const allowedToTransferInBillingStatus: BillingStatus[] = [
  'ACTIVE',
  'TRIALING',
  'INCOMPLETE',
  'PAST_DUE',
]
const filterDestinationWorkspaces = (
  otherWorkspaces: WorkspaceWrapped[]
): WorkspaceMinimalFragment[] => {
  return otherWorkspaces
    .filter(workspace => {
      if (!isEditableWorkspace(workspace.data)) return false
      if (workspace.type === 'PERSONAL') return false

      const currentRegular = workspace.data
      const billingStatus = currentRegular.customer?.billing?.status
      if (!billingStatus) return false

      if (allowedToTransferInBillingStatus.includes(billingStatus)) return true
      return false
    })
    .map(x => x.data)
}

const canTransferToOtherWorkspaces = (
  currentWorkspace: WorkspaceWrapped
): boolean => {
  if (currentWorkspace.type === 'PERSONAL') return false

  const currentRegular = currentWorkspace.data

  if (currentRegular.customer?.billing?.status === 'TRIALING') {
    return false
  }

  return currentRegular.userRole === 'ADMIN'
}

const filterWorkspaces = (
  currentWorkspace: WorkspaceWrapped,
  otherWorkspaces: WorkspaceWrapped[]
): WorkspaceMinimalFragment[] => {
  if (!canTransferToOtherWorkspaces(currentWorkspace)) {
    return [currentWorkspace.data]
  }

  return [
    currentWorkspace.data,
    ...filterDestinationWorkspaces(otherWorkspaces),
  ]
}

const normalizeWorkspaces = (
  query: GetWorkspacesQuery,
  currentWorkspaceId: string
) => {
  const personalWorkspaceWrapped: WorkspaceWrapped | null = query.me
    .personalWorkspace
    ? {
        type: 'PERSONAL',
        data: query.me.personalWorkspace,
      }
    : null

  const otherWorkspacesWrapped: WorkspaceWrapped[] = query.me.workspaces.map(
    x => ({
      type: 'REGULAR',
      data: x,
    })
  )
  const allWorkspacesWrapped = [
    ...[personalWorkspaceWrapped].filter(isTruthy),
    ...otherWorkspacesWrapped,
  ]

  // push current workspace to the top
  let currentWorkspace: WorkspaceWrapped | null = null
  const otherWorkspaces: WorkspaceWrapped[] = []

  for (const workspace of allWorkspacesWrapped) {
    if (workspace.data.identifier === currentWorkspaceId) {
      currentWorkspace = workspace
    } else {
      otherWorkspaces.push(workspace)
    }
  }

  return { currentWorkspace, otherWorkspaces }
}

export const useGetWorkspaces = (currentWorkspaceId: string) => {
  const { data, loading, error } = useGetWorkspacesQuery({
    onCompleted() {
      // Don't use onCompleted - it is not fired when the query is fetched from the cache
      // see: https://github.com/apollographql/react-apollo/issues/2177
    },
  })

  const workspaces = useMemo<WorkspaceMinimalFragment[]>(() => {
    if (!data) return []
    const { currentWorkspace, otherWorkspaces } = normalizeWorkspaces(
      data,
      currentWorkspaceId
    )

    if (!currentWorkspace) {
      ErrorHandler.shouldNeverHappen(
        'The current workspace is not found, currentWorkspaceId: ' +
          currentWorkspaceId
      )
      return []
    }

    return filterWorkspaces(currentWorkspace, otherWorkspaces)
  }, [data, currentWorkspaceId])

  return { workspaces, loading, error }
}
