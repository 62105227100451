import React, { FC } from 'react'

import { Navbar, useForTablet } from '@sketch/components'
import { ArtboardDetailInfoFragment } from '@sketch/gql-types'
import { ReactComponent as PlayIcon } from '@sketch/icons/play-20'
import { RouteParams } from '@sketch/modules-common'
import { Zoom, ZoomProps, ZoomTypes } from '../../../../../components/Zoom'
import { Link, useParams } from 'react-router-dom'

import ArtboardPaginationControls from '../../../components/ArtboardPaginationControls'
import HeaderNavigation from '../../../components/HeaderNavigation'
import { Pagination } from '../../../types'
import { ShareWithoutVersion, useVersioning } from '../../../../versioning'

import { NavbarItem } from './ArtboardDetailHeader.styles'
import { useGridAndLayoutPropForZoomComponent } from '../../hooks'

const UntypedArtboardPaginationControls = ArtboardPaginationControls as any

type ArtboardDetailHeaderEmbeddedProps = {
  share: ShareWithoutVersion
  artboard: ArtboardDetailInfoFragment | undefined
  pagination?: Pagination
  showPrototypePlayButton: boolean
  onZoomChanged: ZoomProps['setZoom']
  currentZoom: ZoomTypes
}

export const ArtboardDetailHeaderEmbedded: FC<ArtboardDetailHeaderEmbeddedProps> = ({
  share,
  artboard,
  showPrototypePlayButton,
  pagination,
  currentZoom,
  onZoomChanged,
}) => {
  const { getPathname } = useVersioning()
  const { shareID } = useParams<RouteParams<'ARTBOARD_DETAIL'>>()

  const isTabletAndBigger = useForTablet()
  const showPagination = pagination && isTabletAndBigger

  const prototypePath = getPathname({
    routeKey: 'PROTOTYPE_PLAYER',
    routeParams: {
      shareID,
      currentArtboardUUID: artboard?.uuid!,
      prototypeArtboardUUID: artboard?.uuid!,
    },
  })

  const gridAndLayoutPropsForZoomComponent = useGridAndLayoutPropForZoomComponent()

  return (
    <Navbar>
      <Navbar.Section>
        <HeaderNavigation share={share} frame={artboard} />
      </Navbar.Section>

      <Navbar.FixedWidthSection align="end">
        {showPrototypePlayButton && (
          <Link to={prototypePath} title="Play prototype">
            <Navbar.Button icon={PlayIcon} />
          </Link>
        )}

        {showPagination && (
          <NavbarItem>
            <UntypedArtboardPaginationControls
              current={pagination.current}
              total={pagination.total}
              showNumbers
            />
          </NavbarItem>
        )}

        <NavbarItem>
          <Zoom
            zoom={currentZoom}
            setZoom={onZoomChanged}
            gridAndLayout={gridAndLayoutPropsForZoomComponent}
            explicitControls
          />
        </NavbarItem>
      </Navbar.FixedWidthSection>
    </Navbar>
  )
}
