import React, { useContext } from 'react'
import styled from 'styled-components'

import { LaunchSketchAlert } from '@sketch/modules-common'
import {
  DocumentDownloadFragment,
  ShareInfoFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { DownloadProps } from '../../utils'
import { useBreakpoint, Dropdown, ModalContext, Flex } from '@sketch/components'

import AppLogo from '@sketch/icons/logo-2x.png'

const AppIcon = styled.img.attrs({ src: AppLogo })`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`

export interface ShareForDownloadOptions
  extends Pick<
    ShareInfoFragment,
    'userCanUpdateSettings' | 'identifier' | 'userCanEdit' | 'type'
  > {}

interface DownloadOptionsProps {
  share: ShareForDownloadOptions
  download: DownloadProps
  document: DocumentDownloadFragment
  isViewingLatestVersion?: boolean
  workspaceStatus?: WorkspaceMinimalFragment['status']
}

const DownloadOptions: React.FC<DownloadOptionsProps> = ({
  share,
  document,
  download,
  isViewingLatestVersion,
  workspaceStatus,
}) => {
  const { showModal } = useContext(ModalContext)
  const isDesktop = useBreakpoint('sm')
  const isActive = workspaceStatus === 'ACTIVE'
  // if there is no workspace status, it's because it's a public library
  const isPublic = workspaceStatus === undefined

  const showLibraryItem = share.type === 'LIBRARY' && (isActive || isPublic)

  const showOpenInSketchItem = document?.userCanOpenInApp || false
  const isTemplate = share.type === 'TEMPLATE'

  const elements: React.ReactNode[] = []

  if (showLibraryItem && isDesktop) {
    elements.push(
      <Dropdown.Item
        key={2}
        onClick={() =>
          showModal(LaunchSketchAlert, {
            shareId: share.identifier,
            action: 'addLibrary',
          })
        }
      >
        Add Library
      </Dropdown.Item>
    )
  }

  if (showOpenInSketchItem && isViewingLatestVersion && isDesktop)
    elements.push(
      <Dropdown.Item
        key={0}
        onClick={event => {
          event.preventDefault()

          showModal(LaunchSketchAlert, {
            shareId: share.identifier,
            action: 'openInSketch',
          })
        }}
      >
        <Flex alignItems="center">
          {isTemplate ? (
            'New from Template'
          ) : (
            <>
              Edit in <AppIcon alt="Sketch" />
            </>
          )}
        </Flex>
      </Dropdown.Item>
    )

  if (download.isAvailable) {
    elements.push(
      <Dropdown.Download key={1} href={download.url}>
        <Flex alignItems="center">Download</Flex>
      </Dropdown.Download>
    )
  }

  if (elements.length <= 0) return null
  return <>{elements}</>
}

export default DownloadOptions
