import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

import {
  IndexLayoutExtraProps,
  IndexLayoutContent,
  IndexLayoutHeaderLink,
  useSignOut,
  routes,
} from '@sketch/modules-common'
import { DocumentHead, LoadingPlaceholder } from '@sketch/components'

import { Wrapper } from './SignOut.styles'
import { useHistory, useLocation } from 'react-router'

export const SignOutView = (props: IndexLayoutExtraProps) => {
  const { HeaderPortal } = props

  const location = useLocation()
  const history = useHistory()
  const signOut = useSignOut({
    redirectBackAfterLoginAgain: false,
    reason: 'Logout button',
  })

  useEffect(() => {
    const asyncSignOut = async () => {
      await signOut()
      history.replace({
        pathname: routes.SIGN_IN.create({}),
        state: location.state,
      })
    }

    asyncSignOut()
  }, [signOut, history, location])

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>
      <Helmet>
        <link rel="canonical" href="https://www.sketch.com/signin/" />
        <meta property="og:url" content="https://www.sketch.com/signin/" />
      </Helmet>
      <DocumentHead title="Sign in - It's great to see you again" />
      <Wrapper>
        <LoadingPlaceholder size="64px" />
      </Wrapper>
    </IndexLayoutContent>
  )
}
