import React from 'react'

import { ModalInjectedProps, ToastLink } from '@sketch/components'

import { ProjectFragment, useUpdateProjectMutation } from '@sketch/gql-types'
import ProjectIdentityModalContent, {
  FormProps,
} from '../ProjectIdentityContent'
import { useToast } from '@sketch/toasts'
import { routes } from '@sketch/modules-common'

interface EditProjectModalProps extends ModalInjectedProps {
  project: Pick<ProjectFragment, 'name' | 'description' | 'identifier'>
  workspaceIdentifier: string
}

export const EditProjectModal = (props: EditProjectModalProps) => {
  const { project, workspaceIdentifier, hideModal } = props
  const { showToast } = useToast()

  const [editProject, { loading }] = useUpdateProjectMutation({
    onError: 'show-toast',
    onCompleted: ({ updateProject }) => {
      const { name, identifier } = updateProject.project

      showToast(
        <>
          &ldquo;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              workspaceId: workspaceIdentifier,
              projectId: identifier,
            })}
          >
            {name}
          </ToastLink>
          &rdquo; changes saved
        </>
      )

      hideModal?.()
    },
  })

  const handleSubmit = (values: FormProps) => {
    editProject({
      variables: {
        identifier: project.identifier,
        name: values.name,
        description: values.description ?? '',
      },
    })
  }

  return (
    <ProjectIdentityModalContent
      title={`Manage “${project.name}”`}
      initialValues={{
        name: project.name,
        description: project.description || '',
      }}
      onSubmit={handleSubmit}
      isLoading={loading}
      hideModal={hideModal}
    />
  )
}

export default EditProjectModal
