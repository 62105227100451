import React from 'react'

import { ModalInjectedProps } from '@sketch/components'

import { ProjectFragment } from '@sketch/gql-types'
import ProjectIdentityModalContent, {
  FormProps,
} from '../ProjectIdentityContent'
import { useCreateSimpleProject } from '../../hooks/useCreateProject'

interface CreateNestedProjectModalProps extends ModalInjectedProps {
  project: ProjectFragment
  workspaceIdentifier: string
}

export const CreateNestedProjectModal = (
  props: CreateNestedProjectModalProps
) => {
  const { project, workspaceIdentifier, hideModal } = props

  const { createProject, loading } = useCreateSimpleProject({
    onCompleted: () => hideModal?.(),
  })

  const handleSubmit = (values: FormProps) => {
    createProject({
      name: values.name,
      description: values.description ?? '',
      workspaceId: workspaceIdentifier,
      parentProjectIdentifier: project.identifier,
    })
  }

  return (
    <ProjectIdentityModalContent
      title={`Create a Project inside “${project.name}”`}
      onSubmit={handleSubmit}
      isLoading={loading}
      hideModal={hideModal}
    />
  )
}
