import React from 'react'

import UserDropdown from './UserDropdown'

import {
  useGetNewNotificationsCount,
  useUserProfile,
} from '@sketch/modules-common'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

interface UserNavbarSectionProps {
  /**
   * To hide parts of user nav section when there's no workspace
   */
  workspace?: WorkspaceMinimalFragment
}

const UserNavbarSection: React.FC<UserNavbarSectionProps> = ({ workspace }) => {
  const { data } = useUserProfile(true)
  const newNotificationsCount = useGetNewNotificationsCount({
    fetchPolicy: 'cache-only',
  })

  return (
    <UserDropdown
      user={data?.me}
      unreadNotifications={newNotificationsCount}
      workspace={workspace}
    />
  )
}

export default UserNavbarSection
