import React from 'react'
import { useInspectorContext, useUserColorFormat } from '../../hooks/inspector'
import {
  isArtboardElement,
  SketchArtboardElement,
  SketchLayerElement,
  SketchElement,
  isPageElement,
  ElementType,
  isLayerWithSharedStyle,
  isLayerElement,
} from '../../../../inspector'

import {
  PositionAndSize,
  Overview,
  InspectorSkeleton,
  LayoutSettings,
  GridSettings,
  ArtboardOverview,
  Corners,
  Appearance,
  Text,
  TextContent,
  Style,
  TextOrLayerStyles,
  SymbolInSidebar,
  Exports,
} from './sections'
import { SidebarEmpty } from '@sketch/components'

import * as S from './ArtboardDetailInspector.styles'
import {
  useEditableComponentDescription,
  useSetupComponentDescriptions,
} from './useEditableComponentDescription'
import { EditableComponentDescription } from '../../../ComponentsWebView/components/ComponentDescription'
import { ShareWithoutVersion } from '../../../../versioning'
import { VersionFragment } from '@sketch/gql-types'
import { useComponentsTrigger } from '../../../components/ComponentsStateContext'

import { ReactComponent as CodeIcon } from '@sketch/icons/code-block-64'

type ArtboardDetailInspectorProps = {
  share: ShareWithoutVersion
  currentVersion: VersionFragment | undefined
}

/**
 * getInspectedElement
 *
 * This method is in change of deciding what element should be presented on the
 * inspector sidebar
 *
 * Having a selected element always takes precedence because it was a deliberated action
 * from the user
 *
 * If the root element is a artboard meaning we are on the artboard view, and nothing has been
 * selected we show the artboard element, this behaviour exists to prevent layers that have the
 * same size as the artboard to block his selection
 *
 * If we aren't on a artboard root element we should be on a page one, in this case it's normal we default
 * to having the selected element rule
 */
const getInspectedElement = (
  sketchSceneRootElement: SketchElement | null,
  selectedElement: SketchElement | null
) => {
  if (selectedElement) {
    return selectedElement
  }

  if (sketchSceneRootElement && isArtboardElement(sketchSceneRootElement)) {
    return sketchSceneRootElement
  }

  return selectedElement
}

export function ArtboardDetailInspector({
  share,
  currentVersion,
}: ArtboardDetailInspectorProps) {
  const {
    selectedElement,
    sketchSceneRootElement,
    inspectorStatus,
  } = useInspectorContext()

  const inspectedElement = getInspectedElement(
    sketchSceneRootElement,
    selectedElement
  )

  // Start fetching before isInspectorReady is true
  useSetupComponentDescriptions(
    share.identifier,
    inspectedElement,
    currentVersion
  )

  // Trigger async components ingestion
  useComponentsTrigger()

  if (inspectorStatus === 'pending') {
    return <InspectorSkeleton />
  }

  if (inspectorStatus === 'errored') {
    return (
      <S.Container>
        <SidebarEmpty
          title="Inspector failed to load"
          description={
            <>
              We’re looking into the issue. Try again later or visit our{' '}
              <a href="https://status.sketch.com/">Status Page</a>.
            </>
          }
        />
      </S.Container>
    )
  }

  if (!inspectedElement) {
    return (
      <S.Container>
        <SidebarEmpty
          title="Select a layer to inspect it"
          icon={CodeIcon}
          description="Right-click or Control-click on any layer, then select it in the menu."
        />
      </S.Container>
    )
  }

  if (isPageElement(inspectedElement)) {
    throw new Error('Page element not yet supported in inspector')
  }

  return (
    <S.Container>
      <Overview sketchElement={inspectedElement} />
      {isArtboardElement(inspectedElement) && (
        <ArtboardElementInfo artboardElement={inspectedElement} />
      )}
      <ElementInfo
        currentVersion={currentVersion}
        element={inspectedElement}
        shareIdentifier={share.identifier}
      />
    </S.Container>
  )
}

type ArtboardElementInfoProps = {
  artboardElement: SketchArtboardElement
}

function ArtboardElementInfo({ artboardElement }: ArtboardElementInfoProps) {
  const {
    description,
    isEnabled,
    editDescriptionHandler,
  } = useEditableComponentDescription()

  return (
    <>
      {artboardElement.type === ElementType.SymbolMaster && (
        <EditableComponentDescription
          description={description}
          disabled={!isEnabled}
          onEdit={editDescriptionHandler}
        />
      )}
      {'preset' in artboardElement && (
        <ArtboardOverview
          preset={artboardElement.preset}
          frame={artboardElement.rootRelativeBoxRect}
        />
      )}
      {'layoutSettings' in artboardElement && (
        <LayoutSettings layout={artboardElement.layoutSettings} />
      )}
      {'gridSettings' in artboardElement && (
        <GridSettings grid={artboardElement.gridSettings} />
      )}
    </>
  )
}

type ElementInfoProps = {
  element: SketchLayerElement | SketchArtboardElement
  currentVersion: VersionFragment | undefined
  shareIdentifier: string
}
function ElementInfo({
  element,
  currentVersion,
  shareIdentifier,
}: ElementInfoProps) {
  const { hasAnyLayerWithExportableAssets } = useInspectorContext()
  const [colorFormat, setColorFormat] = useUserColorFormat()

  const isDirty =
    element.style && 'isDirty' in element.style ? element.style?.isDirty : false

  const textOrLayerStyle = isLayerWithSharedStyle(element.style)
    ? element.style.sharedStyleReference
    : undefined

  const hasSymbols =
    isLayerElement(element) &&
    (element.type === ElementType.SymbolInstance ||
      !!element.parentSymbolLayerElement)

  return (
    <>
      <PositionAndSize
        x={element.rootRelativeBoxRect.x}
        y={element.rootRelativeBoxRect.y}
        width={element.layerSize.width}
        height={element.layerSize.height}
        rotate={isLayerElement(element) ? element.rotation : undefined}
      />

      {/* Text or Layer styles */}
      {textOrLayerStyle && currentVersion && (
        <TextOrLayerStyles
          isDirty={isDirty}
          isTextStyleComponent={element.type === ElementType.Text}
          shareIdentifier={shareIdentifier}
          currentVersion={currentVersion}
          sharedStyleReference={textOrLayerStyle}
        />
      )}

      {isLayerElement(element) && (
        <>
          {element.corners && <Corners corners={element.corners} />}
          {element.appearance && <Appearance appearance={element.appearance} />}
        </>
      )}
      <TextSection sketchElement={element} />
      {element.style && (
        <Style
          style={element.style}
          onColorFormatChange={setColorFormat}
          colorFormat={colorFormat}
          layerType={element.type}
        />
      )}
      {isLayerElement(element) && element.text && (
        <TextContent>{element.text.string}</TextContent>
      )}

      {/* Symbols */}
      {hasSymbols && <SymbolInSidebar layerElement={element} />}

      {currentVersion && (
        <S.ExportsWrapper>
          <Exports
            exportType="layer"
            exportFormats={element.exportFormats}
            hasOtherExportableLayers={hasAnyLayerWithExportableAssets}
            currentVersion={currentVersion}
            exportableAssetId={element.exportId}
            nodeIdentifier={element.identifier}
            elementName={element.name}
          />
        </S.ExportsWrapper>
      )}
    </>
  )
}

type TextSectionProps = {
  sketchElement: SketchElement
}

function TextSection({ sketchElement }: TextSectionProps) {
  const [colorFormat, setColorFormat] = useUserColorFormat()

  if (sketchElement.type !== ElementType.Text || !sketchElement.text) {
    return null
  }

  const style = sketchElement.style

  return (
    <Text
      attributedString={sketchElement.text}
      verticalAlignment={style.verticalAlignment}
      originalSharedStyleValues={style.originalSharedStyleValues}
      onColorFormatChange={setColorFormat}
      colorFormat={colorFormat}
    />
  )
}
