import { useShareDrop } from '@sketch/modules-common'

interface ShareListItemDragDataProps {
  shareId: string
}

export interface DragAndDroppedShareIds {
  draggedShareId: string
  droppedShareId: string
}

interface UseShareListItemDragDropProps {
  disabled: boolean
  shareId: string
  onDropped: (args: DragAndDroppedShareIds) => void
}

export const useShareListItemDragDrop = <T extends HTMLElement>({
  disabled,
  shareId,
  onDropped,
}: UseShareListItemDragDropProps) => {
  const dropEvents = useShareDrop({
    onDropped: ({ shareId: draggedShareId }: ShareListItemDragDataProps) => {
      onDropped({ draggedShareId, droppedShareId: shareId })
    },
  })

  const onDragStart = (event: React.DragEvent<T>) => {
    event.stopPropagation()

    /** Set data-being-dragged to true so we know which element started the drag */
    /** This value is later reset in onDragEnd (down below) */
    const element = event.target as HTMLElement
    element.dataset.beingDragged = 'true'

    const stringifiedParameters = JSON.stringify({
      shareId,
    } as ShareListItemDragDataProps)

    event.dataTransfer.setData('text', stringifiedParameters)

    if (!(event.target instanceof Element)) {
      // Typescript will complain if we try to use `event.target.querySelector`
      // without first refining the type by asserting that `event.target` is an
      // `Element`
      return
    }

    // Set a custom drag image if one is specified
    const dragImage = event.target.querySelector('[data-drag-image]')
    if (dragImage) {
      event.dataTransfer.setDragImage(dragImage, 0, 0)
    }
  }

  const onDragEnd = (event: React.DragEvent<T>) => {
    /** Reset the data-being-dragged attribute  */
    const element = event.target as HTMLElement
    element.dataset.beingDragged = 'false'
  }

  return {
    draggable: !disabled,
    onDragStart,
    onDragEnd,
    ...dropEvents,
  }
}
