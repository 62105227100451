import React from 'react'

import { ExportableIcon } from '../../../../ExportableIcon'
import { useFlag } from '@sketch/modules-common'

import artboardIconURL from '@sketch/icons/artboard-16.svg'
import frameIconURL from '@sketch/icons/frame-16.svg'
import textLayerIconURL from '@sketch/icons/text-format-16.svg'
import shapeLayerIconURL from '@sketch/icons/squares-2-16.svg'
import bitmapLayerIconURL from '@sketch/icons/image-16.svg'
import layerGroupIconURL from '@sketch/icons/folder-closed-16.svg'
import sliceIconURL from '@sketch/icons/square-knife-16.svg'
import symbolIconURL from '@sketch/icons/symbol-16.svg'
import canvasDottedIconURL from '@sketch/icons/canvas-dotted-18.svg'

import * as S from './ElementIcon.styles'
import { ElementType } from '../../../../../../../inspector'

export interface ElementIconProps {
  elementType: ElementType
  className?: string
  hasExports: boolean
  isSymbol: boolean
}

export function ElementIcon({
  elementType,
  hasExports,
  isSymbol,
  className,
}: ElementIconProps) {
  const isFramesWebOn = useFlag('frames-web')

  return (
    <S.ElementIcon className={className}>
      {/* Slice already has an exportable icon present on the icon */}
      {hasExports && elementType !== 'slice' && (
        <S.ExportableIconWrapper elementType={elementType}>
          <ExportableIcon maskColor="#F0F0F0" />
        </S.ExportableIconWrapper>
      )}
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img {...imagePropsForSelection(elementType, isSymbol, isFramesWebOn)} />
    </S.ElementIcon>
  )
}

const imagePropsForSelection = (
  elementType: ElementType,
  isSymbol: boolean,
  isFramesWebOn: boolean
) => {
  if (isSymbol) {
    return {
      src: symbolIconURL,
      alt: 'Symbol icon',
      width: '17px',
      height: '17px',
    }
  }
  switch (elementType) {
    case 'text':
      return {
        src: textLayerIconURL,
        alt: 'Text layer icon',
        width: '16px',
        height: '16px',
      }

    case 'polygon':
    case 'triangle':
    case 'star':
    case 'rectangle':
    case 'oval':
    case 'shapePath':
    case 'shapeGroup':
      return {
        src: shapeLayerIconURL,
        alt: 'Shape layer icon',
        width: '16px',
        height: '16px',
      }

    case 'bitmap':
      return {
        src: bitmapLayerIconURL,
        alt: 'Bitmap layer icon',
        width: '16px',
        height: '16px',
      }

    case 'group':
      return {
        src: layerGroupIconURL,
        alt: 'Layer group icon',
        width: '16px',
        height: '16px',
      }

    case 'artboard':
      return {
        src: isFramesWebOn ? frameIconURL : artboardIconURL,
        alt: 'Artboard icon',
        width: '20px',
        height: '18px',
      }

    case 'slice':
      return {
        src: sliceIconURL,
        alt: 'Slice icon',
        width: '16px',
        height: '16px',
      }

    case 'hotspot': {
      return {
        // This was actually the page icon in the old inspector
        // which was used as default icon for unknown layers.
        // MSImmutableHotspotLayer did not have any icon so it was ending-up with this one.
        // We are just replicating the old behaviour here.
        src: canvasDottedIconURL,
        alt: 'Hotspot icon',
        width: '18px',
        height: '18px',
      }
    }
  }
}
