import {
  CollapsibleTree,
  SetChildrenTreeItemParams,
  TreeNodeData,
} from './CollapsibleTree'

// State handling inspired by https://www.w3.org/WAI/ARIA/apg/patterns/treeview/examples/treeview-1b/

export interface TreeStateDefaults {
  selectedId: string | null
  focusedId: string | null
}

export interface CurrentIdsInput {
  readonly workspaceId: string
  readonly projectId: string | null
}

export interface CurrentIds extends CurrentIdsInput {
  readonly currentId: string
}

export type UpdateReason =
  | 'selected'
  | 'focused'
  | 'open'
  | 'setChildren'
  | 'creatingProject'

export interface CreatingProjectState {
  id: string
  loading: boolean
  name?: string
}

export type CreatingProjectCurrentState = 'pending' | 'loading' | null

export class TreeState<Payload extends {}> {
  /**
   * Id of a node that is currently picked.
   * e.g. project where currently the document belongs to.
   *
   * It is not expected that this id changes frequently (or at all) during the lifetime of the component.
   */
  private readonly _currentDestinationId: string | null = null
  get currentDestinationId(): string | null {
    return this._currentDestinationId
  }

  get currentDestination(): TreeNodeData<Payload> | null {
    if (!this._currentDestinationId) return null
    return this._tree.getNode(this._currentDestinationId) || null
  }

  /**
   * Id of a node that is currently selected (but not necessarily focused).
   */
  private _selectedId: string | null = null
  get selectedId(): string | null {
    return this._selectedId
  }

  get selected(): TreeNodeData<Payload> | null {
    if (!this._selectedId) return null
    return this._tree.getNode(this._selectedId) || null
  }

  /**
   * Id of a node that is currently focused (but not necessarily selected).
   */
  private _focusedId: string | null = null
  get focusedId(): string | null {
    return this._focusedId
  }

  get focused(): TreeNodeData<Payload> | null {
    if (!this._focusedId) return null
    return this._tree.getNode(this._focusedId) || null
  }

  private _creatingProjectState: CreatingProjectState | null = null
  get creatingProjectState(): CreatingProjectState | null {
    return this._creatingProjectState
  }

  private listeners: Map<
    string,
    Set<(reason: UpdateReason) => void>
  > = new Map()
  private globalListeners: Set<(reason: UpdateReason) => void> = new Set()
  private nodeRefs: Map<string, HTMLLIElement> = new Map()

  private _tree: CollapsibleTree<Payload>
  public get tree(): ReadonlyByPrefix<CollapsibleTree<Payload>> {
    return this._tree
  }

  readonly currentIds: CurrentIds

  constructor(current: CurrentIdsInput, defaults: TreeStateDefaults) {
    this.currentIds = {
      ...current,
      currentId: current.projectId || current.workspaceId,
    }
    this._currentDestinationId = this.currentIds.currentId

    this._selectedId = defaults.selectedId
    this._focusedId = defaults.focusedId

    this._tree = new CollapsibleTree<Payload>()
  }

  focusNode(nodeId: string): void {
    if (!nodeId) return
    const ref = this.nodeRefs.get(nodeId)

    if (ref) ref.focus()

    const previouslyFocusedId = this._focusedId

    this._focusedId = nodeId
    this.notifyListeners('focused', previouslyFocusedId, nodeId)
  }

  isCurrentDestination(id: string): boolean {
    return this._currentDestinationId === id
  }

  isOpen(id: string): boolean {
    return this._tree.isOpen(id)
  }

  isSelected(id: string): boolean {
    return this._selectedId === id
  }

  isFocused(id: string): boolean {
    return this._focusedId === id
  }

  getCreatingProjectState(id: string): CreatingProjectCurrentState {
    if (this._creatingProjectState?.id !== id) return null
    return this._creatingProjectState.loading ? 'loading' : 'pending'
  }

  clearCreatingProjectState(causedById: string | null): void {
    if (!this._creatingProjectState) return

    this.onCreatingProject(causedById || '', null)
  }

  onSelect(id: string): void {
    this.clearCreatingProjectState(id)

    const previouslySelectedId = this._selectedId
    const previouslyFocusedId = this._focusedId

    this._selectedId = id
    this._focusedId = id

    this.notifyListeners(
      'selected',
      previouslySelectedId,
      previouslyFocusedId,
      id
    )
  }

  // TODO: decide if we want to change the current destination (maybe we are throwing away the whole tree after that anyway)
  // onCurrentChange(id: string): void {
  //   const previousCurrent = this._currentDestinationId

  //   this._currentDestinationId = id

  //   this.notifyListeners(previousCurrent, id)
  // }

  onFocusedChange(id: string, isFocused: boolean): void {
    const previouslyFocusedId = this._focusedId

    if (isFocused) {
      this._focusedId = id
    } else if (this._focusedId === id) {
      this._focusedId = null
    }

    this.notifyListeners('focused', previouslyFocusedId, id)
  }

  onOpenChange(id: string, isOpen: boolean): void {
    this.clearCreatingProjectState(id)

    this._tree.setIsOpen(id, isOpen)
    this.notifyListeners('open', id)
  }

  onCreatingProject(id: string, state: CreatingProjectCurrentState): void {
    const previousId = this._creatingProjectState?.id || null

    try {
      if (!state) {
        this._creatingProjectState = null
        return
      }

      this._creatingProjectState = { id, loading: state === 'loading' }
    } finally {
      this.notifyListeners('creatingProject', id, previousId)
    }
  }

  openAllToNode(id: string): void {
    this.clearCreatingProjectState(id)

    const parentsPath = this._tree.getParentsPathToNode(id)
    parentsPath.forEach(id => this._tree.setIsOpen(id, true))
    this._tree.setIsOpen(id, true)

    this.notifyListeners('open', ...parentsPath, id)
  }

  private alreadyOpenedOnce: Set<string> = new Set()
  /**
   * We want to intialize the tree with all nodes opened to the node that is currently selected.
   * However, we want to do it only once, for the first time the tree is loaded.
   *
   * If user opens/closes nodes later on AND we unmound/remount the project picker (without dropping the state),
   * We don't want to reinitialize the tree to the selected node.
   */
  setOpenToOnce(id: string): void {
    if (this.alreadyOpenedOnce.has(id)) return
    this.alreadyOpenedOnce.add(id)
    this.openAllToNode(id)
  }

  setChildren(id: string, children: TreeNodeData<Payload>[]): void {
    this._tree.setChildren(id, children)
    this.notifyListeners('setChildren', id)
  }

  setChildrenTree<T extends Payload>(args: {
    list: T[]
    itemParams: (item: T) => SetChildrenTreeItemParams
  }): void {
    const { list, itemParams } = args
    const itemParamsMap = new Map(list.map(x => [x, itemParams(x)]))

    const existingParentIds = args.list
      .map(x => itemParamsMap.get(x)?.parentId || null)
      .filter(x => x && this._tree.getNode(x))

    this._tree.setChildrenTree({ list, itemParamsMap })

    this.notifyListeners('setChildren', ...existingParentIds)
  }

  onRefLoaded(id: string, ref: HTMLLIElement): void {
    this.nodeRefs.set(id, ref)
  }

  registerUpdateListener(
    id: string,
    listener: (reason: UpdateReason) => void
  ): () => void {
    if (!this.listeners.has(id)) {
      this.listeners.set(id, new Set())
    }

    const listenersSet = this.listeners.get(id)!
    listenersSet.add(listener)

    return () => {
      listenersSet.delete(listener)
    }
  }

  registerUpdatesListener(
    listener: (reason: UpdateReason) => void
  ): () => void {
    this.globalListeners.add(listener)
    return () => {
      this.globalListeners.delete(listener)
    }
  }

  private notifyListeners(
    reason: UpdateReason,
    ...ids: (string | null)[]
  ): void {
    const uniqueIds = new Set(ids)
    for (const id of uniqueIds) {
      if (!id) continue
      const listeners = this.listeners.get(id)
      if (!listeners) continue

      for (const listener of listeners) {
        listener(reason)
      }
    }

    this.notifyGlobalListeners(reason)
  }

  private notifyGlobalListeners(reason: UpdateReason): void {
    for (const listener of this.globalListeners) {
      listener(reason)
    }
  }
}
