import React, { useEffect, useRef, FC, KeyboardEvent } from 'react'
import { useFormik } from 'formik'
import { isMac, useIsMountedRef, markdown } from '@sketch/utils'
import * as yup from 'yup'

import { ReactComponent as AtIcon } from '@sketch/icons/at-email-24'
import { ReactComponent as EmojiIcon } from '@sketch/icons/emoji-face-24'
import {
  Tooltip,
  Breakpoint,
  Button,
  SupportsTouch,
  SuggestionsInput,
} from '@sketch/components'

import {
  Footer,
  Wrapper,
  IconButton,
  ActionsWrapper,
} from './CommentInput.styles'

import { ActiveEditingComment } from '../CommentEditContext'
import useMentionableUsers from '../../operations/useMentionableUsers'

export interface CommentInputProps {
  shareIdentifier: string
  className?: string
  autoFocus?: boolean
  onCommentSubmit: (comment: string) => void
  onCommentChange?: (comment: string) => void
  onCancel?: () => void
  isReply?: boolean
  isEditing?: boolean
  comment?: string
  banner?: React.ReactElement
}

const commentSchema = yup.object().shape({
  comment: yup
    .string()
    .test(
      'empty-check',
      'Comments cannot be blank',
      value => typeof value === 'string' && value.trim().length > 0
    ),
})

export const shortcutSuggestionTooltipContent = () =>
  isMac() ? 'Command-Enter' : 'Control-Enter'

export const CommentInput: FC<CommentInputProps> = props => {
  const {
    autoFocus,
    shareIdentifier,
    className,
    onCommentSubmit,
    onCommentChange,
    onCancel,
    isReply,
    isEditing,
    comment = '',
    banner,
  } = props

  const isComponentMounted = useIsMountedRef()

  const {
    values,
    setValues,
    handleSubmit,
    dirty,
    isValid,
    submitForm,
  } = useFormik({
    initialValues: { comment },
    validationSchema: commentSchema,
    onSubmit: async ({ comment }, { resetForm }) => {
      if (!comment) return
      onCommentSubmit?.(comment)

      // Prevent state from changing if the component is already unmounted
      isComponentMounted.current && resetForm()
    },
  })

  // Focus on the comment input when thread panel loads
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const input = inputRef.current

    if (autoFocus) {
      if (!input) {
        return
      }

      // Ensure that the input is mounted before setting focus
      setTimeout(() => {
        input.focus()

        // Force the caret to be on the end of the input
        const textLength = input.textContent?.length || 0
        input.selectionStart = textLength
        input.selectionEnd = textLength
      })

      return () => {
        if (document.activeElement === input) {
          input.blur()
        }
      }
    }
  }, [autoFocus])

  useEffect(() => {
    setValues({ comment })
  }, [comment, setValues])

  const handleSuggestionsKeyDown = (event: KeyboardEvent) => {
    const auxKey = event.metaKey || event.ctrlKey

    /* Check if submit key command */
    if (auxKey && event.key === 'Enter') {
      /* Submit the form so the comment can be created */
      submitForm()
      return
    }

    const markDownChange = markdown.getChangeByEventKey(event.key)
    if (auxKey && markDownChange) {
      event.preventDefault()

      if (inputRef.current) {
        const { selectionStart, selectionEnd } = inputRef.current

        const { text, ...selection } = markdown.toggleTextWithChange(
          inputRef.current.value,
          markDownChange,
          selectionStart,
          selectionEnd
        )

        setValues({ comment: text }).then(() => {
          /* Force the input selection after the text change  */
          inputRef?.current?.setSelectionRange(
            selection.selectionStart,
            selection.selectionEnd
          )
        })
      }
      return
    }
  }

  const insertEmojiTrigger = () => {
    // Insert extra space after the current value except if the current value is empty
    setValues({ comment: `${values.comment} :`.trim() })

    inputRef.current?.focus()

    // This prompt safari to show the dialog with options
    inputRef.current?.setSelectionRange(0, 1, 'backward')
  }

  const insertMentionTrigger = () => {
    // Insert extra space after the current value except if the current value is empty
    setValues({ comment: `${values.comment} @`.trim() })

    inputRef.current?.focus()

    // This prompt safari to show the dialog with options
    inputRef.current?.setSelectionRange(0, 1, 'backward')
  }

  const [queryUsers, { loading, users, called }] = useMentionableUsers(
    shareIdentifier
  )

  useEffect(() => {
    if (!called && values.comment.charAt(values.comment.length - 1) === '@') {
      queryUsers()
    }
  }, [queryUsers, called, values])

  const mentionableUsers = users.map(user => ({
    // TODO: mark mentionableUsers as non_null on GraphQL schema
    // https://github.com/sketch-hq/Cloud/issues/4828
    id: user!.identifier,
    display: user!.name || undefined,
    avatar: user!.avatar!.small || '',
  }))

  const allowSubmit = (dirty || comment) && isValid

  return (
    <Wrapper ref={wrapperRef} className={className} data-testid="comment-input">
      {/**
       * "ActiveEditingComment" will warn the parent context (if exists) that
       * there's a comment being edited, if the user tries to edit another comment
       * a modal should show and prompt the user to choose between one or the other
       *
       * See "modules/annotations/components/CommentEditContext/CommentEditContext.tsx"
       * for more context
       */}
      {isEditing && (
        <ActiveEditingComment
          value={values.comment}
          isDirty={dirty}
          onCloseEditMode={() => {
            // In case we need to close this component
            // we should "submit" the original text
            onCommentSubmit(comment)
          }}
          onFocusComment={() => {
            inputRef.current?.focus()
          }}
        />
      )}
      {banner}
      <form onSubmit={handleSubmit}>
        <SuggestionsInput
          name="comment"
          inputRef={inputRef}
          value={values.comment}
          mentionableUsers={mentionableUsers}
          onChange={event => {
            setValues({ comment: event.target.value })
            onCommentChange?.(event.target.value)
          }}
          onKeyDown={handleSuggestionsKeyDown}
          placeholder={isReply ? 'Write a reply...' : 'Write a comment...'}
        />

        {/*
          We shouldn't show the emoji icon in mobile, since most phone keyboards
          have emoji support build in.

          https://github.com/sketch-hq/Cloud/issues/2004#issuecomment-539848866
        */}
        <Footer>
          <Breakpoint on="sm">
            <IconButton
              type="button"
              size="32"
              onClick={insertEmojiTrigger}
              data-testid="emoji-icon"
              variant="secondary"
            >
              <EmojiIcon role="img" />
              <span className="sr-only">Show Emoji selector</span>
            </IconButton>
          </Breakpoint>

          <IconButton
            type="button"
            size="32"
            loading={loading}
            onClick={insertMentionTrigger}
            data-testid="mention-icon"
            variant="secondary"
          >
            <AtIcon role="img" />
            <span className="sr-only">Show Mentions selector</span>
          </IconButton>

          <ActionsWrapper>
            {isEditing && (
              <Button type="submit" size="32" onClick={onCancel}>
                Cancel
              </Button>
            )}

            <SupportsTouch>
              {supportsTouch => (
                <Tooltip
                  content={shortcutSuggestionTooltipContent()}
                  placement="top"
                  disabled={supportsTouch || !allowSubmit}
                >
                  {isEditing ? (
                    <Button
                      type="submit"
                      size="32"
                      disabled={!allowSubmit}
                      variant="primary"
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      type="submit"
                      size="32"
                      disabled={!allowSubmit}
                    >
                      Send
                    </Button>
                  )}
                </Tooltip>
              )}
            </SupportsTouch>
          </ActionsWrapper>
        </Footer>
      </form>
    </Wrapper>
  )
}

export default CommentInput
