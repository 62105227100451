import { Modal, Button as ButtonBase } from '@sketch/components'
import styled, { css } from 'styled-components'

export const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Footer = styled.div<{ dimmed?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.border.A};

  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 16px;

  & > * {
    ${({ dimmed }) =>
      dimmed &&
      css`
        opacity: 0.3;
        pointer-events: none;
      `}
  }
`

export const Button = styled(ButtonBase)`
  flex-grow: 0;
`
