import React from 'react'
import {
  useGetWorkspaceMembersCountWithCustomerQuery,
  useGetWorkspaceQuery,
} from '@sketch/gql-types'
import { routes, useFlag } from '@sketch/modules-common'

import { INVITEE_LIMIT, MEMBERS_LIMIT_ON_TRIAL } from '../constants'
import { Link, pluralize } from '@sketch/components'

export const getInviteLimitMessage = (
  limit: NonNullable<ReturnType<typeof useMemberLimit>>
) => {
  const { type, hasReachedLimit, remaining } = limit

  if (type === 'member-count') {
    const subscriptionSetUp = limit.isSubscriptionSetUp

    const countMessage = hasReachedLimit ? (
      'You have reached the workspace members limit'
    ) : (
      <>
        You can invite up to{' '}
        <b>
          {remaining} {pluralize('member', 'members', remaining)}
        </b>{' '}
        more
      </>
    )

    if (subscriptionSetUp) {
      return {
        tooltip: hasReachedLimit ? (
          'You have reached the member limit'
        ) : (
          <>
            You can invite up to {remaining}{' '}
            {pluralize('member', 'members', remaining)}
          </>
        ),
        helpText: (
          <>
            {countMessage}. Need to invite more than{' '}
            {MEMBERS_LIMIT_ON_TRIAL.WITH_SUBSCRIPTION} members during your
            trial?{' '}
            <Link
              href="https://www.sketch.com/support/contact/"
              variant="tertiary"
              isUnderlined
              external
            >
              Contact our customer support team
            </Link>
          </>
        ),
      }
    } else {
      return {
        tooltip: hasReachedLimit ? (
          'You have reached the member limit'
        ) : (
          <>
            You can invite up to {remaining}{' '}
            {pluralize('member', 'members', remaining)}
          </>
        ),
        helpText: (
          <>
            {countMessage}. To invite up to{' '}
            {MEMBERS_LIMIT_ON_TRIAL.WITH_SUBSCRIPTION} members in your trial,
            please{' '}
            <Link
              to={routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.create({})}
              variant="tertiary"
              isUnderlined
            >
              add your payment details
            </Link>
          </>
        ),
      }
    }
  }

  return {
    tooltip: `You can only invite ${limit.limitValue} members at a time`,
    helpText: `Invite up to ${remaining} Members at a time. To invite more, create another round of invitations.`,
  }
}

export const useMemberLimit = (workspaceId: string, listCount: number = 0) => {
  // Remove this FF on
  // https://github.com/sketch-hq/Cloud/issues/19536
  const isTrialAbuse = useFlag('trial-abuse')

  // Used to check its type for invitee limits
  const { data: workspaceData } = useGetWorkspaceQuery({
    variables: { identifier: workspaceId },
    fetchPolicy: 'cache-only',
  })

  const isBusinessWorkspace = workspaceData?.workspace.type === 'BUSINESS'

  const isTrialling =
    workspaceData?.workspace.customer?.billing?.status === 'TRIALING'

  const isSubscriptionSetUp =
    workspaceData?.workspace.customer?.billing?.subscriptionSetUp

  const hasUnlimitedTrialFlag =
    workspaceData?.workspace.features.unlimitedTrialEnabled

  const { data, loading } = useGetWorkspaceMembersCountWithCustomerQuery({
    variables: { workspaceId },
    fetchPolicy: 'cache-and-network',
    skip: !isTrialAbuse && (!isTrialling || hasUnlimitedTrialFlag),
  })

  // Let's wait to get the workspace data before showing incorrect messages
  if (loading) {
    return null
  }

  if (isTrialling && !hasUnlimitedTrialFlag && isTrialAbuse) {
    const workspaceInviteLimit = isSubscriptionSetUp
      ? MEMBERS_LIMIT_ON_TRIAL.WITH_SUBSCRIPTION
      : MEMBERS_LIMIT_ON_TRIAL.WITHOUT_SUBSCRIPTION

    const totalWorkspaceMembersCount =
      data?.workspace.memberships.totalMemberships?.meta.totalCount || 0

    return {
      type: 'member-count' as const,
      get remaining() {
        return Math.max(
          workspaceInviteLimit - totalWorkspaceMembersCount - listCount,
          0
        )
      },
      get hasReachedLimit() {
        const count = this.remaining
        return count === 0
      },
      isSubscriptionSetUp,
      limitValue: workspaceInviteLimit,
    }
  }

  const limitByType = isBusinessWorkspace
    ? INVITEE_LIMIT.business
    : INVITEE_LIMIT.standard

  return {
    type: 'rate' as const,
    get remaining() {
      return Math.max(limitByType - listCount, 0)
    },
    get hasReachedLimit() {
      const count = this.remaining
      return count === 0
    },
    isSubscriptionSetUp,
    limitValue: limitByType,
  }
}
