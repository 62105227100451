import React from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { ArtboardDetailInfoFragment } from '@sketch/gql-types'
import { IS_EMBEDDED } from '@sketch/constants'
import {
  useForLargerThanMobile,
  IsEmbedded,
  NotEmbedded,
} from '@sketch/components'
import { formatProjectName } from '../../../projects/utils'
import { ShareWithoutVersion } from '../../../versioning'
import {
  RouteParams,
  routes,
  useUserSignedIn,
  CWV_ROUTES,
} from '@sketch/modules-common'

import { NavigationContainer } from './HeaderNavigation.styles'
import NavigationItem from './NavigationItem'
import { FramesNavigationItem } from './FramesNavigationItem'
import PagesNavigationItem from './PagesNavigationItem'
import CWVGroupNavigationItem from './CWVGroupNavigationItem'
import Breadcrumbs from './Breadcrumbs'
import { useShareViewsNavigation } from '../../utils/shareViewsNavigation'

// get label and link for PROJECT item
const useProjectNav = (share: ShareWithoutVersion) => {
  const isMobile = !useForLargerThanMobile()
  const isSignedIn = useUserSignedIn()
  const project = share.project
  const projectName = project ? formatProjectName(project) : ''

  if (!projectName || !isSignedIn || IS_EMBEDDED || isMobile) {
    return null
  }

  const projectId = project?.identifier ?? ''
  const workspaceId = share.workspace.identifier
  const projectLink =
    project?.type === 'PERSONAL_DRAFTS'
      ? routes.WORKSPACE_DRAFTS.create({ workspaceId })
      : routes.WORKSPACE_PROJECT.create({ projectId, workspaceId })

  return { projectName, projectLink }
}

// check PAGE item visibility
const usePageNav = () => {
  const match = useRouteMatch<RouteParams<'SHARE_PAGE_VIEW'>>()
  const isCwvRoute = !!CWV_ROUTES.find((route: string) => route === match.path)

  // hide on CWV route or if number of pages < 2
  return !isCwvRoute
}

const useCollectionNav = (share: ShareWithoutVersion) => {
  const { collection, project, workspace } = share

  if (!collection || !project) {
    return null
  }

  const link = routes.WORKSPACE_COLLECTION.create({
    workspaceId: workspace.identifier,
    projectId: project.identifier,
    collectionId: collection.identifier,
  })

  const name = collection.name

  return { link, name }
}

export type HeaderNavigationProps = {
  share: ShareWithoutVersion
  frame?: ArtboardDetailInfoFragment
  className?: string
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  share,
  frame,
}) => {
  const projectNav = useProjectNav(share)
  const showPage = usePageNav()
  const collectionNav = useCollectionNav(share)
  const { previousView } = useShareViewsNavigation()
  const location: {
    state?: { groupId: string; symbolName: string }
  } = useLocation()

  const documentName = share.name
  const documentLink = routes.SHARE_VIEW.create({ shareID: share.identifier })
  const collapseDocument = showPage && !!frame

  // make sure we're coming from a symbol and its data is set
  const isSymbolsView =
    previousView?.view === 'symbols' &&
    location.state?.groupId &&
    location.state?.symbolName

  return (
    <NavigationContainer>
      <NotEmbedded>
        <Breadcrumbs>
          {projectNav && (
            <NavigationItem
              collapsed={true}
              to={projectNav.projectLink}
              icon="project"
              label={projectNav.projectName}
            />
          )}
          {collectionNav && (
            <NavigationItem
              collapsed={true}
              to={collectionNav.link}
              icon="collection"
              label={collectionNav.name}
            />
          )}
          <NavigationItem
            collapsed={collapseDocument}
            to={documentLink}
            icon={share.type === 'TEMPLATE' ? 'template' : 'document'}
            label={documentName}
          />
          {isSymbolsView ? (
            <CWVGroupNavigationItem
              shareIdentifier={share.identifier}
              groupId={location.state?.groupId ?? ''}
              symbolName={location.state?.symbolName ?? ''}
            />
          ) : (
            <PagesNavigationItem frame={frame} share={share} />
          )}
          {!isSymbolsView && frame && (
            <FramesNavigationItem
              frame={frame}
              shareIdentifier={share.identifier}
            />
          )}
        </Breadcrumbs>
      </NotEmbedded>
      <IsEmbedded>
        <Breadcrumbs>
          {!frame && (
            <NavigationItem
              to={documentLink}
              icon="document"
              label={documentName}
            />
          )}
          {!frame && <PagesNavigationItem frame={frame} share={share} />}
          {frame && (
            <FramesNavigationItem
              frame={frame}
              shareIdentifier={share.identifier}
            />
          )}
        </Breadcrumbs>
      </IsEmbedded>
    </NavigationContainer>
  )
}

export default HeaderNavigation
