import React, { FC, PropsWithChildren } from 'react'
import { useModalContext } from '@sketch/components'
import { ShareInfoFragment } from '@sketch/gql-types'
import { Footer, Button, ModalStyled } from './MoveDocumentModalLayout.styles'
import { MoveDocumentModalHeader } from './MoveDocumentModalHeader'

export interface MoveDocumentModalLayoutProps {
  share?: Pick<
    ShareInfoFragment,
    'name' | 'identifier' | 'project' | 'workspace' | 'version'
  >
}

export const MoveDocumentModalLayout: FC<MoveDocumentModalLayoutProps> = props => {
  const hideModal = useModalContext().hideModal

  const { share, children } = props

  return (
    <ModalStyled onCancel={hideModal}>
      <MoveDocumentModalHeader share={share} />
      {children}
    </ModalStyled>
  )
}

export interface MoveDocumentModalFooterProps extends PropsWithChildren<{}> {
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
  dimmed?: boolean
}
export const MoveDocumentModalFooter: FC<MoveDocumentModalFooterProps> = props => {
  const hideModal = useModalContext().hideModal
  const { disabled, loading, onClick, children, dimmed } = props

  return (
    <Footer dimmed={dimmed}>
      {children}
      <Button onClick={hideModal}>Cancel</Button>
      <Button
        variant="primary"
        disabled={disabled}
        loading={loading}
        onClick={onClick}
      >
        Move Document
      </Button>
    </Footer>
  )
}
