import React, { useEffect } from 'react'
import { useTreeState } from './treeState.context'

export const useIsCreatingProject = () => {
  const treeState = useTreeState()
  const [isCreatingProject, setIsCreatingProject] = React.useState(
    !!treeState.creatingProjectState
  )

  useEffect(() => {
    const cleanup = treeState.registerUpdatesListener(reason => {
      if (reason !== 'creatingProject') return
      setIsCreatingProject(!!treeState.creatingProjectState)
    })
    return cleanup
  }, [treeState])

  return { isCreatingProject }
}
