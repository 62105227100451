import React, { useRef } from 'react'
import { useGetWorkspaces, useTreeState, useIsCreatingProject } from './state'
import { useTreeKeyboardNavigation } from './state.base'
import { ProjectTreeNodes } from './TreeNode.projects'
import { GenericError, WorkspaceLogo } from '@sketch/components'
import { TreeNode, TreeNodeSkeleton } from './TreeNode'
import { CollapsibleTree } from './state.base/CollapsibleTree'
import { useOnListChange } from './state/useOnListChange'
import { TreeContainer, TreeOverlay } from './TreeNode.styles'
import { useCreateSimpleProject } from '../../hooks/useCreateProject'

export const WorkspaceTreeNodes = () => {
  const treeState = useTreeState()
  const { isCreatingProject } = useIsCreatingProject()

  const currentWorkspaceId = treeState.currentIds.workspaceId

  const { error, loading, workspaces } = useGetWorkspaces(currentWorkspaceId)

  const { createProject } = useCreateSimpleProject()

  useOnListChange(workspaces, (items, isFirstChange) => {
    treeState.setChildren(
      CollapsibleTree.ROOT_ID,
      items.map(x => ({ id: x.identifier, payload: x, hasChildren: true }))
    )

    if (isFirstChange) treeState.setOpenToOnce(currentWorkspaceId)
  })

  const containerRef = useRef<HTMLDivElement>(null)
  useTreeKeyboardNavigation(containerRef, treeState)

  if (loading) {
    return (
      <div>
        <TreeNodeSkeleton depth={0} />
        <TreeNodeSkeleton depth={1} />
        <TreeNodeSkeleton depth={1} />
        <TreeNodeSkeleton depth={0} />
        <TreeNodeSkeleton depth={0} />
      </div>
    )
  }

  if (error || !workspaces) {
    return <GenericError />
  }

  return (
    <TreeContainer
      ref={containerRef}
      role="tree"
      creatingProject={isCreatingProject}
    >
      {isCreatingProject && <TreeOverlay />}
      {workspaces.map(x => (
        <TreeNode
          key={x.identifier}
          id={x.identifier}
          depth={0}
          onCreateProject={async name => {
            const result = await createProject({
              workspaceId: x.identifier,
              name,
            })
            return result?.identifier
          }}
          icon={
            <WorkspaceLogo
              size="16px"
              workspaceName={x.name}
              src={x.avatar?.small || x.avatar?.large}
            />
          }
          hasChildren={true}
          label={<div>{x.name}</div>}
        >
          <ProjectTreeNodes key={x.identifier} workspaceId={x.identifier} />
        </TreeNode>
      ))}
    </TreeContainer>
  )
}
