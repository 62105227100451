import styled, { css } from 'styled-components'
import { ButtonUnstyled, Dropdown } from '@sketch/components'
import { ReactComponent as PrototypeSvg } from '@sketch/icons/prototype-hotspot-16'
import { ReactComponent as ArtboardSvg } from '@sketch/icons/artboard-16'
import { ReactComponent as FrameSvg } from '@sketch/icons/frame-16'
import { ReactComponent as ChevronsSvg } from '@sketch/icons/chevrons-up-down-16'

export const SelectorButton = styled(ButtonUnstyled)`
  min-width: 0px;
  max-width: 100%;
  display: flex;
  align-items: center;
`

export const SelectorIcon = styled(ChevronsSvg)`
  width: 16px;
  height: 16px;
  margin: 0 0 0 4px;
  flex: none;
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
`

export const DropdownItem = styled(Dropdown.Item.NavLink)`
  align-items: center;
  display: flex;

  svg {
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
    width: 16px;
    height: 16px;
    margin-right: 12px;
    margin-bottom: 1px; /* stylelint-disable scales/space */
  }

  && {
    &.focus,
    &.active,
    &[data-current] {
      svg {
        color: ${({ theme }) => theme.colors.sketch.A};
      }
    }
  }
`

const dropdownItemIconCss = css`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  width: 16px;
  height: 16px;
  flex: none;
  margin-left: 20px;
`

export const StartPointIcon = styled(PrototypeSvg)`
  ${dropdownItemIconCss}
`

export const ArtboardIcon = styled(ArtboardSvg)`
  ${dropdownItemIconCss}
`

export const FrameIcon = styled(FrameSvg)`
  ${dropdownItemIconCss}
`
