import React, { FC } from 'react'

import { SelectDropdownLinkItem, UnstyledList } from '@sketch/components'
import UserNavbarSection from '../../../user/components/UserNavbarSection'
import { WorkspacesDropdown } from '../../containers/WorkspacesDropdown'

import { ReactComponent as BackArrow } from '@sketch/icons/arrow-top-left-16'
import { ReactComponent as Workspaces } from '@sketch/icons/workspaces-16'
import { ReactComponent as People } from '@sketch/icons/persons-2-16'
import { ReactComponent as Card } from '@sketch/icons/card-default-16'
import { ReactComponent as Lock } from '@sketch/icons/lock-16'
import { ReactComponent as PersonCircle } from '@sketch/icons/person-circle-16'
import { ReactComponent as CircleHalfFilled } from '@sketch/icons/circle-half-filled-16'

import { useThemeContext } from '@sketch/global-styles'

import {
  SidebarWrapper,
  WorkspaceSelectorWrapper,
  MenuWrapper,
  Header,
  MenuListItem,
  Spacer,
} from './Sidebar.styles'

// GQL
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

import { WorkspaceSidebarNotice } from '../WorkspaceSidebarNotice'
import { routes, useFlag } from '@sketch/modules-common'

import { CanRenderOptions } from '../../types'
import { useMenuPermissions } from './hooks'

interface WorkspaceSettingsSidebarMenuProps {
  workspaceId: string
  canRender: CanRenderOptions
}

const WorkspaceSettingsSidebarMenu: FC<WorkspaceSettingsSidebarMenuProps> = ({
  workspaceId,
  canRender,
}) => (
  <>
    <Header>Workspace</Header>
    <UnstyledList role={'menu'} data-testid="workspace-settings-navbar">
      {canRender.generalMenu && (
        <MenuListItem>
          <SelectDropdownLinkItem
            icon={Workspaces}
            text="General"
            to={routes.WORKSPACE_SETTINGS_SETTINGS.create({
              workspaceId,
            })}
          />
        </MenuListItem>
      )}
      {canRender.peopleMenu && (
        <MenuListItem>
          <SelectDropdownLinkItem
            icon={People}
            text="People"
            to={routes.WORKSPACE_SETTINGS_PEOPLE.create({
              workspaceId,
            })}
          />
        </MenuListItem>
      )}
      {canRender.permissionGroups && (
        <SelectDropdownLinkItem
          data-testid="workspace-settings-groups-link"
          icon={Lock}
          text="Groups"
          to={routes.WORKSPACE_SETTINGS_PERMISSION_GROUPS.create({
            workspaceId,
          })}
        />
      )}
      {canRender.profileMenu && (
        <MenuListItem>
          <SelectDropdownLinkItem
            icon={Lock}
            text="Profile"
            to={routes.WORKSPACE_SETTINGS_PROFILE.create({
              workspaceId,
            })}
          />
        </MenuListItem>
      )}
      {canRender.billingMenu && (
        <MenuListItem>
          <SelectDropdownLinkItem
            icon={Card}
            text="Billing"
            to={routes.WORKSPACE_SETTINGS_BILLING.create({
              workspaceId,
            })}
          />
        </MenuListItem>
      )}
      {canRender.SSOMenu && (
        <MenuListItem>
          <SelectDropdownLinkItem
            icon={Lock}
            text="Single Sign-On"
            to={routes.WORKSPACE_SETTINGS_SSO.create({ workspaceId })}
          />
        </MenuListItem>
      )}
    </UnstyledList>

    <Spacer />
  </>
)

export const UserSettingsSidebarMenu = () => {
  const isDarkModeToggleEnabled = useFlag('dark-mode-toggle')

  return (
    <>
      <Header>Account</Header>
      <UnstyledList role={'menu'}>
        <MenuListItem>
          <SelectDropdownLinkItem
            icon={PersonCircle}
            text="General"
            to={routes.PERSONAL_SETTINGS.create({})}
            exact
          />
        </MenuListItem>
        {isDarkModeToggleEnabled && (
          <MenuListItem>
            <SelectDropdownLinkItem
              icon={CircleHalfFilled}
              text="Appearance"
              to={routes.APPEARANCE_SETTINGS.create({})}
              exact
            />
          </MenuListItem>
        )}
      </UnstyledList>
    </>
  )
}
interface SettingsSidebarProps {
  workspace: WorkspaceMinimalFragment
}

export const SettingsSidebar: FC<SettingsSidebarProps> = ({ workspace }) => {
  const workspaceId = workspace.identifier
  const isPersonalWorkspace = workspace.type === 'PERSONAL'

  const { isDarkMode } = useThemeContext()

  const {
    isFinance,
    isPartner,
    isGuest,
    canRender,
    loading,
  } = useMenuPermissions({
    workspace,
  })

  // We render blank sidebar while the menu permissions are being
  // loaded to avoid menu items from flickering with different workspace types
  if (loading) {
    return null
  }

  const backRoute = isPersonalWorkspace
    ? // For Personal Workspaces we need to redirect to the All Documents
      routes.ALL_DOCUMENTS.create({})
    : // Entry will calculate the correct route to send the user depending on multiple conditions
      routes.WORKSPACE_SHARES.create({ workspaceId })

  return (
    <SidebarWrapper>
      <WorkspaceSelectorWrapper>
        <WorkspacesDropdown workspace={workspace} />
        <UserNavbarSection workspace={workspace} />
      </WorkspaceSelectorWrapper>

      <WorkspaceSidebarNotice workspace={workspace} />

      <MenuWrapper data-testid="settings-sidebar" $isDarkMode={isDarkMode}>
        {!isFinance && !isPartner && (
          <>
            <MenuListItem>
              <SelectDropdownLinkItem
                icon={BackArrow}
                // if the user does not have a workspace, the view shown is `NoWorkspaceSidebar`, so the Back button is different
                text="Back to Documents"
                to={backRoute}
              />
            </MenuListItem>

            <Spacer />
          </>
        )}
        {!isGuest && (
          <WorkspaceSettingsSidebarMenu
            workspaceId={workspaceId}
            canRender={canRender}
          />
        )}

        <UserSettingsSidebarMenu />
      </MenuWrapper>
    </SidebarWrapper>
  )
}
