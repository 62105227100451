import { truncateStyles } from '@sketch/components'
import { breakpoint } from '@sketch/global-styles'
import styled from 'styled-components'

export const Footnote = styled.div`
  margin-right: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  ${breakpoint('base', 'xxs')`
    display: none;
  `}
`

export const FootnoteText = styled.span`
  ${truncateStyles};
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
`
