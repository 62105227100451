import styled from 'styled-components'

export const NavbarItem = styled.div`
  display: flex;
  margin: 0 8px;

  & + & {
    margin-left: 0;
  }
`

export const Wrapper = styled.div`
  display: inline-flex;
  gap: 4px;

  ${/* sc-selector */ NavbarItem}:first-child {
    margin-left: 0px;
  }
`
