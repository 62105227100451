import React, { useCallback } from 'react'

import { ToastLink } from '@sketch/components'

import {
  GetProjectsDocument,
  useCreateProjectMutation,
  CreateProjectMutationVariables,
  useApolloClient,
} from '@sketch/gql-types'

import { useToast } from '@sketch/toasts'
import { routes } from '@sketch/modules-common'
import { useEventDispatch } from '@sketch/utils'

interface NestedProjectCreatedPayload {
  projectId: string
  parentProjectId: string
}

declare module '@sketch/utils' {
  export interface EventsMap {
    nestedProjectCreated: NestedProjectCreatedPayload
  }
}

interface UseCreateSimpleProjectProps {
  onCompleted?: () => void
}

const useRefreshGetProjects = () => {
  const client = useApolloClient()
  return useCallback(
    (workspaceId: string) =>
      client.query({
        query: GetProjectsDocument,
        variables: { workspaceId },
        fetchPolicy: 'network-only',
      }),
    [client]
  )
}

export const useCreateSimpleProject = (
  props: UseCreateSimpleProjectProps = {}
) => {
  const { onCompleted } = props
  const { showToast } = useToast()
  const dispatchNestedProjectCreated = useEventDispatch('nestedProjectCreated')
  const dispatchSharesRefresh = useEventDispatch('workspaceShareRefresh')
  const refreshGetProjects = useRefreshGetProjects()

  const [createProjectBase, { loading }] = useCreateProjectMutation({
    onError: 'show-toast',
    onCompleted: () => {},
  })

  const createProject = useCallback(
    async (variables: CreateProjectMutationVariables) => {
      const {
        workspaceId,
        parentProjectIdentifier: parentProjectId,
      } = variables
      const result = await createProjectBase({ variables })
      await refreshGetProjects(workspaceId)

      const createWorkspaceProject = result.data?.createWorkspaceProject
      if (
        !createWorkspaceProject ||
        result.error ||
        (result.errors?.length || 0) > 0
      ) {
        return
      }

      const { name, identifier } = createWorkspaceProject.project

      dispatchSharesRefresh({
        workspaceIdentifier: workspaceId,
        projectIdentifier: identifier,
        onlyProjects: true,
      })

      if (parentProjectId) {
        dispatchNestedProjectCreated({ projectId: identifier, parentProjectId })
      }

      showToast(
        <>
          &quot;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              workspaceId: workspaceId,
              projectId: identifier,
            })}
          >
            {name}
          </ToastLink>
          &quot; project created
        </>
      )

      onCompleted?.()

      return createWorkspaceProject.project
    },
    [
      createProjectBase,
      dispatchNestedProjectCreated,
      dispatchSharesRefresh,
      onCompleted,
      refreshGetProjects,
      showToast,
    ]
  )

  return { createProject, loading }
}
