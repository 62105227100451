import styled from 'styled-components'

import { BaseListRow, TextArea } from '@sketch/components'
import {
  InvitePeopleField,
  InvitePeopleFieldInviteButton,
} from '@sketch/modules-common'

export const StyledInviteButton = styled(InvitePeopleFieldInviteButton)`
  width: 68px;
`

export const StyledInvitePeopleField = styled(InvitePeopleField)`
  margin-right: 16px;
  max-width: calc(100% - 85px);
`

export const MembersList = styled.div`
  margin-top: 16px;
`

export const ListRow = styled(BaseListRow)`
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 12px;
  }
`

export const StyledTextArea = styled(TextArea)`
  resize: none;
  width: 100%;
  height: 100px;
`
