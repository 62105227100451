import { castError } from '@sketch/utils'
import { DragEvent, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { DataProxy } from 'apollo-cache'

import { readShareListItemFromCache } from '../utils'
import { ErrorHandler } from '@sketch/tracing'

import { ShareListItemFragment } from '@sketch/gql-types'

const VALID_DATATRANSFER_TYPES = ['Text', 'text/plain']

export interface ShareDropPayload {
  shareId: string
  share: ShareListItemFragment | undefined
}

export interface UseShareDropProps {
  onDropped?: (data: ShareDropPayload) => void
}

export const useShareDrop = ({ onDropped }: UseShareDropProps) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const [isDraggedOverItself, setDraggedOverItself] = useState(false)
  const cache = useApolloClient()

  if (!onDropped) {
    return {
      kind: 'disabled',
    } as const
  }

  const resetDraggedOver = () => {
    setIsDraggedOver(false)
    setDraggedOverItself(false)
  }

  const onDragOver = (event: DragEvent<HTMLElement>) => {
    event.preventDefault()

    /*
    The dataTransfer types are expected from the dragged item, in this case the expected ones are "text",
    For this to work in IE11 we would have to use the type "text"
    https://stackoverflow.com/a/18051912

    IE 11 transforms "text" to "Text"
    and others from "text" to "text/plain" 🤦‍♂️
    */
    if (VALID_DATATRANSFER_TYPES.includes(event.dataTransfer.types[0])) {
      /**
       * The code below runs only until setIsDraggedOver(true) to avoid performance issues during drag.
       * The data-being-dragged attribute, set by useShareDrop, helps track the element; check the hook for details.
       */
      if (!isDraggedOver) {
        if (event.target instanceof HTMLElement) {
          const draggedElement = document.querySelector(
            '[data-being-dragged="true"]'
          )
          const isItself = Boolean(draggedElement?.contains(event.target))

          setDraggedOverItself(isItself)
        }

        setIsDraggedOver(true) // This is to prevent the drag over event from being called multiple times
      }
    }
  }

  const onDragLeave = (event: DragEvent<HTMLElement>) => {
    event.preventDefault()

    resetDraggedOver()
  }

  const onDrop = (event: DragEvent<HTMLElement>) => {
    event.preventDefault()

    resetDraggedOver()

    const payload = getEventData(cache, event)
    if (!payload) {
      return
    }

    onDropped(payload)
  }

  return {
    kind: 'enabled',
    isDraggedOver,
    isDraggedOverItself,
    onDrop,
    onDragOver,
    onDragLeave,
  } as const
}

const getEventData = (
  cache: DataProxy,
  event: DragEvent<HTMLElement>
): ShareDropPayload | undefined => {
  const dataType = event.dataTransfer.types[0]

  let dataObject: ShareDropPayload | undefined
  try {
    dataObject = JSON.parse(event.dataTransfer.getData(dataType))
  } catch (e) {
    const error = castError(e)
    ErrorHandler.ignore(error)
  }
  const { shareId } = dataObject || {}

  if (!shareId) {
    return
  }

  const share = readShareListItemFromCache({ cache, id: shareId })

  return { shareId, share }
}
