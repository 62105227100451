import React from 'react'

import {
  Tooltip,
  TruncateWithTooltip,
  TimeAgo,
  useResponsiveDropdown,
  Truncate,
} from '@sketch/components'

import {
  Wrapper,
  Description,
  TopRow,
  Author,
  InfoIcon,
  AutosaveTooltip,
  Text,
  VersionOptionsButton,
  StarWrapper,
  DateTime,
  ToolTipAuthor,
  Order,
  ToggleLinesButton,
} from './VersionItem.styles'

import { ReactComponent as DotIcon } from '@sketch/icons/dots-3-horizontal-16'

// Usage of RenderStatusType will not break the build
// eslint-disable-next-line no-restricted-imports
import { RenderStatusType } from '@sketch/gql-types/expansive'
import { useReadMore } from '../../hooks/useReadMore'

export interface VersionItemProps<T extends React.ComponentType<any>> {
  className?: string
  description?: string
  authorName: string | null
  creationDate: string
  renderStatus?: RenderStatusType
  deleted?: boolean
  optionsMenu?: {
    component: T
    props: React.ComponentProps<T>
  }
  star?: (() => React.ReactNode) | false
  isStarred?: boolean
  isAuto?: boolean
  order?: number
}

export const RENDER_STATUS_COPY = {
  CREATED: 'Uploading…',
  UPLOADING: 'Uploading…',
  UPLOADED: 'Processing…',
  RENDERING: 'Processing…',
  PROCESSING: 'Processing…',
  FAILED: 'Processing failed',
  ERRORED: 'Processing failed',
  LOADING: null,
} as const

const options = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
} as const

const dateFormatter = new Intl.DateTimeFormat('default', options)

const FakeComponent = () => <></>

const VersionItem = <T extends React.ComponentType<any>>(
  props: VersionItemProps<T>
) => {
  const {
    className,
    description,
    authorName,
    creationDate,
    deleted,
    optionsMenu,
    star,
    renderStatus,
    isStarred,
    isAuto,
    order,
  } = props

  const {
    showReadMore,
    descriptionElement,
    isExpanded,
    truncateStyles,
    handleClick,
  } = useReadMore({ description })

  const showAuthor = !isAuto
  const dateFormat = dateFormatter.format(new Date(creationDate)).split(', ')
  const versionFinishedRendering = renderStatus === 'FINISHED'
  const author = authorName ?? 'Deleted User'

  const { component: dropdown, props: dropdownProps } = optionsMenu || {
    component: FakeComponent,
    props: {},
  }

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: dropdown || React.Fragment,
    dropdownProps: dropdownProps,
    placement: 'bottom-end',
    usePortal: true,
  })

  let metaText
  if (!renderStatus || renderStatus === 'FINISHED') {
    metaText = (
      <Tooltip
        content={
          <>
            {dateFormat[0]}
            <br />
            <DateTime>{dateFormat[1]}</DateTime>
          </>
        }
        placement="bottom-start"
      >
        {props => (
          <span {...props}>
            <TimeAgo date={creationDate} short hideTitle />
          </span>
        )}
      </Tooltip>
    )
  } else {
    metaText = RENDER_STATUS_COPY[renderStatus]
  }

  return (
    <Wrapper
      className={className}
      data-testid="version-item"
      $disabled={deleted || !versionFinishedRendering}
      $isStarred={isStarred}
    >
      <TopRow>
        {order && <Order>#{order}</Order>}

        {star && <StarWrapper>{star()}</StarWrapper>}

        {isAuto && (
          <Author>
            <Truncate>
              Autosave
              <AutosaveTooltip
                placement="bottom"
                content="Autosaved versions include the document's latest changes"
              >
                <InfoIcon role="img" />
              </AutosaveTooltip>
            </Truncate>
          </Author>
        )}

        {showAuthor && (
          <Author>
            <TruncateWithTooltip placement="bottom-end">
              <ToolTipAuthor>{author}</ToolTipAuthor>
            </TruncateWithTooltip>
          </Author>
        )}

        <Text>{metaText}</Text>

        {deleted && <Text>(Deleted)</Text>}

        {optionsMenu && (
          <>
            <VersionOptionsButton
              data-testid="version-item-options-button"
              {...buttonProps}
            >
              <DotIcon role="img" />
              <span className="sr-only">Comment options</span>
            </VersionOptionsButton>
            {content}
          </>
        )}
      </TopRow>

      {description && (
        <>
          <Description
            ref={descriptionElement}
            data-testid="version-item-description"
            style={truncateStyles}
          >
            {description}
          </Description>
          {showReadMore && (
            <ToggleLinesButton onClick={handleClick}>
              {isExpanded ? 'Read less' : 'Read more'}
            </ToggleLinesButton>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default VersionItem
