import styled from 'styled-components'
import { Button, DownloadFileAnchor, LinkButton } from '@sketch/components'

export const ActionButton = styled(Button).attrs({ size: '32' })`
  padding: 6px 8px 6px 12px; /* stylelint-disable-line scales/space */
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  box-shadow: none;

  &,
  :hover,
  :focus,
  :active {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }

  &[disabled] {
    opacity: 0.5;
  }
`

export const IconContainer = styled.div`
  display: flex;
`

export const DownloadButton = styled(DownloadFileAnchor).attrs({
  as: LinkButton,
  size: '32',
  external: true,
})`
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.fontSizes.D};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  box-shadow: none;

  &,
  :hover,
  :focus,
  :active {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
  }
`
