import React from 'react'

import { ReactComponent as ImagePlaceholder } from '@sketch/icons/document-64'

import { LoadingPlaceholder } from '@sketch/components'

import { getThumbnail } from '../../../shares/ComponentsWebView/utils'

import { ImageElement } from '../../../shares/components/DocumentItem/DocumentItem.styles'
import {
  StyledStatusIcon,
  Thumbnail,
  ThumbnailWrapper,
  NoThumbnailWrapper,
} from './NestedProjectItem.styles'

import { NestedProjectItemProps } from './types'
import { ShareThumbnailsFragment } from '@sketch/gql-types'

type PreviewThumbnail = NonNullable<
  NonNullable<ShareThumbnailsFragment['thumbnails']>[0]
>

export const renderPreview = (
  props: NestedProjectItemProps,
  layout: 'grid' | 'list'
) => {
  const { nestedProject } = props

  const previews = nestedProject.previews.slice(0, 4)

  return (
    <ThumbnailWrapper aria-label={`Project ${nestedProject.name}`}>
      {previews.map(({ name, file }, index) => {
        const thumbnails = (file?.thumbnails || []).filter(
          thumb => !!thumb
        ) as PreviewThumbnail[]

        if (thumbnails.length === 0) {
          return (
            <NoThumbnailWrapper
              data-testid="nested-project-no-thumb"
              key={index}
            >
              <StyledStatusIcon
                key={index}
                as={ImagePlaceholder}
                aria-label="No Image available"
              />
            </NoThumbnailWrapper>
          )
        } else {
          return (
            <Thumbnail
              key={index}
              alt={name}
              customImageElement={ImageElement}
              src={getThumbnail(thumbnails)}
              loadingPlaceholder={<LoadingPlaceholder size="16px" />}
            />
          )
        }
      })}
    </ThumbnailWrapper>
  )
}
