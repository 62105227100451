import { useCallback, useEffect, useReducer, useState } from 'react'
import { CreatingProjectCurrentState, UpdateReason } from '../state.base'
import { useTreeState } from './treeState.context'

export interface TreeNodeState {
  isOpen: boolean
  isCurrentDestination: boolean
  isSelected: boolean
  isFocused: boolean
  creatingState: CreatingProjectCurrentState

  onSelect(): void
  onOpenChange(isOpen: boolean): void
  onRefLoaded(el: HTMLElement): void
  onFocusedChange(isFocused: boolean): void
  onCreatingProject(state: CreatingProjectCurrentState): void
}

export const useTreeNodeState = (id: string): TreeNodeState => {
  const treeState = useTreeState()
  const [isOpen, setIsOpen] = useState(treeState.isOpen(id))
  const [isSelected, setIsSelected] = useState(treeState.isSelected(id))
  const [isFocused, setIsFocused] = useState(treeState.isFocused(id))
  const [isCurrentDestination, setIsCurrentDestination] = useState(
    treeState.isCurrentDestination(id)
  )
  const [creatingState, setCreatingState] = useState(
    treeState.getCreatingProjectState(id)
  )

  const [, forceRender] = useReducer(s => s + 1, 0)

  useEffect(() => {
    const handleUpdate = (reason: UpdateReason) => {
      setIsOpen(treeState.isOpen(id))
      setIsCurrentDestination(treeState.isCurrentDestination(id))
      setIsSelected(treeState.isSelected(id))
      setIsFocused(treeState.isFocused(id))
      setCreatingState(treeState.getCreatingProjectState(id))

      if (reason === 'setChildren') forceRender()
    }

    return treeState.registerUpdateListener(id, handleUpdate)
  }, [id, treeState])

  const onSelect = useCallback(() => treeState.onSelect(id), [id, treeState])
  const onOpenChange = useCallback(
    (isOpen: boolean) => treeState.onOpenChange(id, isOpen),
    [id, treeState]
  )

  const onRefLoaded = useCallback(
    (ref: HTMLLIElement) => treeState.onRefLoaded(id, ref),
    [id, treeState]
  )
  const onFocusedChange = useCallback(
    (isFocused: boolean) => treeState.onFocusedChange(id, isFocused),
    [id, treeState]
  )
  const onCreatingProject = useCallback(
    (state: CreatingProjectCurrentState) =>
      treeState.onCreatingProject(id, state),
    [id, treeState]
  )

  return {
    isOpen,
    isCurrentDestination,
    isSelected,
    isFocused,
    creatingState,
    onSelect,
    onOpenChange,
    onRefLoaded,
    onFocusedChange,
    onCreatingProject,
  }
}
