import React from 'react'
import { Text } from '@sketch/components'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-left: 4px;
`

const OpacityPercentage = ({ value: opacity }: { value?: number }) =>
  (opacity && opacity < 1) || opacity === 0 ? (
    <Wrapper>
      <Text.Span textStyle="copy.quaternary.standard.D" mr={3}>{`${(
        opacity * 100
      ).toFixed()}%`}</Text.Span>
    </Wrapper>
  ) : null

export default OpacityPercentage
