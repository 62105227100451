import React, { ReactNode } from 'react'
import { useRouteMatch } from 'react-router'
import { InView } from 'react-intersection-observer'

import { useFlag, isProjectTrashRoute } from '@sketch/modules-common'

import {
  Button,
  Dropdown,
  Tooltip,
  useModalContext,
  useResponsiveDropdown,
} from '@sketch/components'

import {
  CopyProjectAction,
  DeleteProjectAction,
  DeleteProjectPermanentlyAction,
  LeaveProjectAction,
  EditProjectAction,
  RenameProjectAction,
  RestoreProjectAction,
  PinProjectAction,
} from '../ProjectDropdownActions'

import ProjectMembershipsTooltip from '../ProjectMembershipsTooltip'

import SharingButton from './SharingButton'

import {
  Dots,
  ButtonWrapper,
  CollectionIcon,
  CreateNestedProjectButton,
  Divider,
  CreateProjectIcon,
} from './ProjectHeader.styles'

import { useGetProjectMembershipsQuery } from '@sketch/gql-types'
import { ProjectHeaderActionsProps } from './types'
import { CreateCollectionModal } from '../../../collections/modals'

import ArchiveProjectModal from '../../modals/ArchiveProjectModal'
import UnarchiveProjectModal from '../../modals/UnarchiveProjectModal'
import { CreateNestedProjectModal } from '../../../projects/modals'

const ActionDropdownWrapper = ({ children }: { children: ReactNode }) => (
  <div onClick={event => event.stopPropagation()}>
    <Dropdown.Header>Project</Dropdown.Header>
    {children}
  </div>
)

export const ProjectHeaderActions: React.FC<ProjectHeaderActionsProps> = ({
  project,
  workspaceAccessLevel,
  workspace,
  userCanRenameAndDeleteProject,
  userCanManageCollection,
  onRenameProject,
  headerSlim,
}) => {
  const { identifier: workspaceId, features } = workspace

  const isNestedProjectsOn = useFlag('nested-projects')

  const { path } = useRouteMatch()
  const isTrashedProjectView = isProjectTrashRoute(path)

  const { showModal } = useModalContext()

  const { error, loading, data } = useGetProjectMembershipsQuery({
    variables: { projectIdentifier: project.identifier },
  })

  const handleCreateNestedProject = () => {
    if (isNestedProjectsOn) {
      showModal(CreateNestedProjectModal, {
        project,
        workspaceIdentifier: workspaceId,
      })
    } else {
      showModal(CreateCollectionModal, {
        project,
        workspaceIdentifier: workspaceId,
      })
    }
  }

  let dropdownAction = null

  const showLeaveAction = project.userCanLeave

  // Only show the divider if there will be buttons beneath it
  const showDivider = userCanRenameAndDeleteProject || showLeaveAction
  const showArchive =
    (workspace.userCanAdminister || workspace.userCanEdit) &&
    features.archiveEnabled

  const pinned = project.pinnedByCurrentUserAt
  const isArchived = !!project.archivedAt

  if (project.deletedAt) {
    dropdownAction = (
      <>
        <CopyProjectAction project={project} workspaceId={workspaceId} />
        <RestoreProjectAction project={project} workspaceId={workspaceId} />
        <Dropdown.Divider />
        {showLeaveAction && (
          <LeaveProjectAction
            projectIdentifier={project.identifier}
            projectName={project.name}
          />
        )}
        <DeleteProjectPermanentlyAction
          project={project}
          workspaceId={workspaceId}
        />
      </>
    )
  } else {
    dropdownAction = (
      <>
        {userCanRenameAndDeleteProject && isNestedProjectsOn && (
          <EditProjectAction workspaceId={workspaceId} project={project} />
        )}
        {userCanRenameAndDeleteProject && (
          <RenameProjectAction onRename={() => onRenameProject(true)} />
        )}
        {!isArchived && (
          <PinProjectAction
            projectIdentifier={project.identifier}
            pinned={pinned}
            workspaceId={workspaceId}
          />
        )}
        <CopyProjectAction project={project} workspaceId={workspaceId} />
        {showArchive &&
          (project.archivedAt ? (
            <Dropdown.Item
              onClick={() => {
                showModal(UnarchiveProjectModal, {
                  projectIdentifier: project.identifier,
                  projectName: project.name,
                  workspaceIdentifier: workspaceId,
                })
              }}
            >
              Unarchive&hellip;
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              onClick={() => {
                showModal(ArchiveProjectModal, {
                  project,
                  workspaceIdentifier: workspaceId,
                })
              }}
            >
              Archive&hellip;
            </Dropdown.Item>
          ))}
        {showDivider && <Dropdown.Divider />}
        {showLeaveAction && (
          <LeaveProjectAction
            projectIdentifier={project.identifier}
            projectName={project.name}
          />
        )}
        {userCanRenameAndDeleteProject && (
          <DeleteProjectAction project={project} workspaceId={workspaceId} />
        )}
      </>
    )
  }

  const [content, buttonProps, { setVisible }] = useResponsiveDropdown({
    dropdown: ActionDropdownWrapper,
    dropdownProps: { children: dropdownAction },
    usePortal: true,
    placement: 'bottom-start',
  })

  const handleOtherActionsDropdown = (visible: boolean) => {
    if (!visible) {
      setVisible(false)
    }
  }

  const createNestedProjectButton = (
    <Tooltip
      placement="top"
      content={isNestedProjectsOn ? 'Create a Project' : 'Create Collection'}
    >
      <CreateNestedProjectButton
        onClick={handleCreateNestedProject}
        $isNestedProjectsOn={isNestedProjectsOn}
      >
        {isNestedProjectsOn ? <CreateProjectIcon /> : <CollectionIcon />}
      </CreateNestedProjectButton>
    </Tooltip>
  )

  if (project.type === 'PERSONAL_DRAFTS') {
    if (!userCanManageCollection) {
      return null
    }

    return <ButtonWrapper>{createNestedProjectButton}</ButtonWrapper>
  }

  return (
    <ButtonWrapper $headerSlim={headerSlim}>
      {!project.archivedAt && (
        <>
          <ProjectMembershipsTooltip
            workspace={workspace}
            memberships={data?.project.members}
            shouldShowWorkspace={workspaceAccessLevel !== 'NONE'}
            workspaceAccessLevel={workspaceAccessLevel}
            disabled={loading || !!error}
          >
            <SharingButton
              project={project}
              workspace={workspace}
              userCanRenameAndDeleteProject={userCanRenameAndDeleteProject}
              memberships={data?.project.members}
              headerSlim={headerSlim}
            />
          </ProjectMembershipsTooltip>

          {userCanManageCollection &&
            !isTrashedProjectView &&
            createNestedProjectButton}
        </>
      )}
      <>
        <Button
          data-testid="project-actions"
          variant="secondary-untinted"
          {...buttonProps}
        >
          <InView onChange={handleOtherActionsDropdown}>
            <span className="sr-only">Other Actions</span>
            <Dots />
          </InView>
        </Button>
        {content}
        {headerSlim && <Divider />}
      </>
    </ButtonWrapper>
  )
}
