import React from 'react'
import { ProjectPreviewFragment } from '@sketch/gql-types'
import { pluralize } from '@sketch/components'
import { Container, FolderIcon, DocumentIcon } from './PreviewSubtitle.styles'

export interface PreviewSubtitleProps {
  project: Pick<ProjectPreviewFragment, 'nestedProjectsCount' | 'sharesCount'>
}

export const PreviewSubtitle = (props: PreviewSubtitleProps) => {
  const {
    project: { nestedProjectsCount, sharesCount },
  } = props

  if (nestedProjectsCount === 0 && sharesCount === 0) {
    return <Container>Empty</Container>
  }

  const projectsText =
    nestedProjectsCount +
    ' ' +
    pluralize('Project', 'Projects', nestedProjectsCount)

  const documentsText =
    sharesCount + ' ' + pluralize('Document', 'Documents', sharesCount)

  if (sharesCount === 0) {
    return (
      <Container>
        <FolderIcon />
        {projectsText}
      </Container>
    )
  }

  if (nestedProjectsCount === 0) {
    return (
      <Container>
        <DocumentIcon />
        {documentsText}
      </Container>
    )
  }

  return (
    <Container>
      <Container title={projectsText}>
        <FolderIcon />
        {nestedProjectsCount}
      </Container>
      <Container title={documentsText}>
        <DocumentIcon />
        {sharesCount}
      </Container>
    </Container>
  )
}
