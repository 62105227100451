import React, { useMemo } from 'react'
import { useVersioning } from '../../../versioning'
import {
  usePrototypeState,
  useRestartPrototype,
  PresentationManifestPage,
  PresentationManifestArtboard,
} from '@sketch-hq/sketch-web-renderer'
import {
  DocumentHead,
  Dropdown,
  useResponsiveDropdown,
} from '@sketch/components'

import NavigationItem from '../../../shares/components/HeaderNavigation/NavigationItem'
import * as S from './PrototypeFlowSelector.styles'
import {
  useManifestPages,
  usePrototypeContext,
  useReplaceUrl,
} from '../../hooks'
import { useFlag } from '@sketch/modules-common'

const DROPDOWN_STYLE = {
  maxHeight: '80vh',
  overflow: 'auto',
} as const

/**
 * The Prototype flow selector dropdown. This component has a few behaviours that are
 * worth spelling out:
 * - By default it only shows Artboards that are start points
 * - By only showing start points, the dropdown lists "the prototypes" available
 *   in the document, not all possible Artboards. So when we move between Screens
 *   within the current prototype, we don't update the value of the dropdown
 * - The idea is that selecting a new start point from the dropdown resets the
 *   view to that new "prototype" (however we want to do that without throwing
 *   away the current renderer instance, manifest file and loaded assets). So
 *   when a new start point is selected:
 *   - We don't navigate and unmount/remount the route
 *   - We use the player API to restart the prototype to the new start point
 *   - We update (replace) the restart point in the url
 * - If we're inside the edge case of the `prototypeArtboardUUID` route param not
 *   being a legit start point in the manifest - then tweak the behaviour
 *   slightly to make it feel less jarring, see implementation for details
 */
export function PrototypeFlowSelector() {
  const { getArtboard, prototypeArtboardUUID } = usePrototypeContext()
  const { currentArtboardId } = usePrototypeState()
  const { share } = useVersioning()
  const isFramesWebOn = useFlag('frames-web')

  const prototypeArtboard = getArtboard(prototypeArtboardUUID)
  const currentArtboard = getArtboard(currentArtboardId)

  // When following links organically in the web app the prototypeArtboardUUID
  // should always be a start point - but in case the user entered the view
  // via a legacy url redirect, and the artboard is not a start point, then
  // this boolean becomes true and we massage the selector a bit
  const prototypeArtboardIsStartPoint = prototypeArtboard?.isStartPoint ?? false

  const prototypeName = prototypeArtboardIsStartPoint
    ? prototypeArtboard?.name
    : currentArtboard?.name

  // useManifestPages uses useMemo
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pages = useManifestPages() ?? []
  const artboards = useMemo(() => pages.flatMap(page => page.artboards), [
    pages,
  ])

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: ArtboardsDropdown,
    dropdownProps: {
      pages,
      currentArtboardId,
      prototypeArtboardUUID,
      prototypeArtboardIsStartPoint,
    },
    dropdownStyle: DROPDOWN_STYLE,
  })

  const artboardCount = artboards.length || 0
  const iconId = prototypeArtboardIsStartPoint
    ? 'prototype'
    : isFramesWebOn
    ? 'artboard'
    : 'frame'

  if (artboardCount === 1) {
    return <NavigationItem icon={iconId} label={artboards[0]?.name || ''} />
  }

  return (
    <>
      <S.SelectorButton
        data-testid="prototype-artboard-selector"
        {...buttonProps}
      >
        {prototypeName && (
          <DocumentHead title={`${prototypeName} - ${share.name}`} />
        )}
        <NavigationItem label={prototypeName} icon={iconId} />
        <S.SelectorIcon />
      </S.SelectorButton>
      {content}
    </>
  )
}

interface ArtboardsDropdownProps {
  pages: PresentationManifestPage[]
  currentArtboardId: string
  prototypeArtboardIsStartPoint: boolean
  prototypeArtboardUUID: string
}

function ArtboardsDropdown(props: ArtboardsDropdownProps) {
  const {
    currentArtboardId,
    prototypeArtboardUUID,
    prototypeArtboardIsStartPoint,
  } = props
  const restartPrototype = useRestartPrototype()
  const replace = useReplaceUrl()
  const { getPathname, share } = useVersioning()

  const pages = useMemo(() => {
    const sortByName = <
      T extends PresentationManifestPage | PresentationManifestArtboard
    >(
      a: T,
      b: T
    ): number => {
      return a.name.localeCompare(
        b.name,
        undefined, // locales, leaving undefined to fallback to the browser locale
        {
          numeric: true,
          sensitivity: 'base',
        }
      )
    }

    return props.pages
      .map(page => ({
        ...page,
        artboards:
          page.artboards
            ?.filter(
              artboard =>
                // Only show start points in the dropdown
                artboard.isStartPoint ||
                // Or if the restart artboard isn't a start point, then also show
                // the current artboard
                (!prototypeArtboardIsStartPoint &&
                  artboard.id === currentArtboardId)
            )
            ?.sort(sortByName) ?? [],
      }))
      .filter(page => page.artboards.length > 0)
      .sort(sortByName)
  }, [currentArtboardId, props.pages, prototypeArtboardIsStartPoint])

  return (
    <>
      {pages.map(page => {
        return (
          <div key={page.id}>
            <Dropdown.Header>{page.name}</Dropdown.Header>
            {page.artboards.map(artboard => (
              <S.DropdownItem
                key={artboard.id}
                isActive={() => {
                  return prototypeArtboardIsStartPoint
                    ? artboard.id === prototypeArtboardUUID
                    : artboard.id === currentArtboardId
                }}
                to={getPathname({
                  routeKey: 'PROTOTYPE_PLAYER',
                  routeParams: {
                    shareID: share.identifier,
                    currentArtboardUUID: artboard.id,
                    prototypeArtboardUUID: artboard.id,
                  },
                })}
                disabled={!artboard.isStartPoint}
                onClick={e => {
                  // We want navigation to a new Screen to occur within the
                  // Prototype renderer only, so prevent the default link behavior
                  e.preventDefault()
                  // Manually update the url with the new start point and restart the prototype
                  replace({ prototypeArtboardUUID: artboard.id })
                  restartPrototype(artboard.id)
                }}
              >
                {artboard.isStartPoint ? (
                  <S.StartPointIcon />
                ) : (
                  <S.ArtboardIcon />
                )}
                {artboard.name}
              </S.DropdownItem>
            ))}
          </div>
        )
      })}
    </>
  )
}
